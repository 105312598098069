import { Grid, Stack, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { loadAuthenticationInfos } from "redux/authenticationInfos/authenticationInfo-actions";
import { useAppDispatch } from "redux/store";
import { useLocation } from "react-router";
import BankList from "components/BankList";

type OwnProps = {};

const useStyles = makeStyles()((theme) => ({
  container: {
    height: "100%",
    margin: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginBottom: "150px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

export const LoginPage: React.FC<OwnProps> = (_props: OwnProps) => {
  const { classes } = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(loadAuthenticationInfos());
  }, [dispatch]);

  return (
    <Stack paddingY={2} className={classes.container}>
      <Typography variant="body2">Tunnistautumispalvelun tarjoaa Telia.</Typography>
      <Grid item xs={8} className={classes.buttonContainer}>
        {location.pathname === "/identify" ? <BankList /> : <h1>error</h1>}
      </Grid>
    </Stack>
  );
};

export default React.memo(LoginPage);
