import React from "react";
import { Grid, Dialog } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import PopupBody from "./PopupBody";
import { PopupActionProps } from "types/types";
import { PopupType } from "types/enums";

const useStyles = makeStyles()({
  root: {
    width: "75%",
    left: "auto !important",
    top: "46px",
  },
  backdropRoot: {
    width: "75%",
    left: "auto",
    top: "46px",
  },
});
interface OwnProps {
  open?: boolean;
  type: PopupType;
  closeFromOutsideDialog?: boolean;
  onInputChangeValue?: (value: string, fieldName: string) => void;
}

type Props = OwnProps & PopupActionProps;

export const Popup = (props: Props): JSX.Element => {
  const { classes } = useStyles();
  const {
    open = false,
    onClose,
    onSubmit,
    type,
    onInputChangeValue,
    closeFromOutsideDialog = true,
  } = props;

  return (
    <Grid container>
      <Grid item xs>
        <Dialog
          open={open}
          onClose={closeFromOutsideDialog ? onClose : undefined}
          classes={{ root: classes.root }}
          BackdropProps={{ classes: { root: classes.backdropRoot } }}
        >
          <PopupBody
            type={type}
            onClose={onClose}
            onSubmit={onSubmit}
            onInputChangeValue={onInputChangeValue}
          />
        </Dialog>
      </Grid>
    </Grid>
  );
};
