/**
 * Simple wrapper for console.log methods
 * Logging is disabled in production builds
 * unless REACT_APP_ENABLE_LOGGING is set to true
 */
function isLoggingEnabled(): boolean {
  const isProduction = process.env.NODE_ENV === "production";
  const enableLogging = process.env.REACT_APP_ENABLE_LOGGING === "true";

  // in non-production builds logging is enabled by default
  if (!isProduction) {
    return true;
  }

  // otherwise log only if REACT_APP_ENABLE_LOGGING is set to "true";
  return enableLogging;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
const noOp = (..._args: any[]): void => {};

export type Logger = Pick<
  Console,
  "debug" | "log" | "info" | "warn" | "error" | "time" | "timeEnd"
>;

const createLogger = (): Logger => {
  if (isLoggingEnabled()) {
    return {
      debug: console.debug.bind(console),
      log: console.log.bind(console),
      info: console.info.bind(console),
      warn: console.warn.bind(console),
      error: console.error.bind(console),
      time: console.time.bind(console),
      timeEnd: console.timeEnd.bind(console),
    };
  } else {
    return {
      debug: noOp,
      log: noOp,
      info: noOp,
      warn: noOp,
      error: noOp,
      time: noOp,
      timeEnd: noOp,
    };
  }
};

export const logger = createLogger();
