import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RoutesEnum } from "App";

type OwnProps = {};

const useStyles = makeStyles()((theme) => ({
  container: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginBottom: "150px",
  },
  messageContainer: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    textAlign: "center",
  },
  loggedOutText: {
    fontFamily: theme.hypoFonts.gotham.book,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "65%",
  },
  logInAgainBtn: {
    marginTop: 30,
    minHeight: 54,
    minWidth: 0,
    width: "80%",
  },
}));

export const LogoutPage: React.FC<OwnProps> = (_props: OwnProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid container item xs={8} className={classes.container}>
        <Grid container item xs={12} className={classes.logoContainer}>
          <img
            className="nav-logo"
            src="https://www.hypo.fi/wp-content/uploads/2015/06/hypo_sin_rgb_web.jpg"
            alt="Hypo Logo"
            width={"65%"}
          />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Typography variant="h5" className={classes.loggedOutText}>
            Uloskirjautuminen onnistui. Voit nyt sulkea selaimen.
          </Typography>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button
              className={classes.logInAgainBtn}
              onClick={() => navigate(RoutesEnum.Login)}
            >
              Kirjaudu uudelleen
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(LogoutPage);
