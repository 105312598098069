import React, { useCallback, useMemo } from "react";
import { Grid, FormControlLabel, Radio } from "@mui/material";
import CustomCheckBoxWithLabel from "components/CustomCheckbox/CustomCheckBoxWithLabel";
import CustomRadioIcon from "components/CustomRadioIcon/CustomRadioIcon";
import CustomRadioIconChecked from "components/CustomRadioIcon/CustomRadioIconChecked";
import moment from "moment";
import { Field, FieldArray } from "redux-form";
import { KYCQuestionType } from "types/enums";
import { Question } from "types/models";
import { mapCountryAnswers, isRequired } from "utils/kycUtils";
import { KYCFormProps } from "./DynamicKYCForm";
import ExtendedField from "./ExtendedField";
import RenderQuestionParameter from "./QuestionParameters/RenderQuestionParameter";
import renderRadio from "./RenderRadio";
import renderTextfield from "./RenderTextField";
import RenderSelect from "./RenderSelect";
import RenderSelectionListQuestion from "./RenderSelectionListQuestion";
import RenderMultipartList from "./RenderMultipartList";

interface Props {
  question: Question;
  kycFormProps: KYCFormProps;
  keyProp?: string;
  innerElement?: React.ReactNode;
  onlyChangeOnBlur?: boolean;
  isMultipartQuestion?: boolean;
  isMultipartQuestionFirstField?: boolean;
  renderAlternativeQuestionTitle?: boolean;
}

const RenderQuestionByType = (props: Props) => {
  const {
    isMultipartQuestion,
    isMultipartQuestionFirstField,
    onlyChangeOnBlur,
    renderAlternativeQuestionTitle,
    kycFormProps,
    question,
    innerElement,
    keyProp,
  } = props;

  const type = question.questionType;
  const formState = kycFormProps.formState;
  const justifyFieldTo =
    !isMultipartQuestion || isMultipartQuestionFirstField
      ? "flex-start"
      : "flex-end";

  const isRequiredMemoized = useCallback(
    () => isRequired(question, formState),
    [question, formState]
  );

  switch (type) {
    case KYCQuestionType.CheckBox:
      return (
        <Grid
          container
          item
          style={{
            justifyContent: justifyFieldTo,
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 0,
            marginRight: 0,
            padding: 0,
          }}
          key={keyProp}
        >
          <Grid item xs={12} md={8}>
            <RenderQuestionParameter
              subHeader={question.parameters.subHeader}
              additionalStyles={{ marginBottom: 15 }}
            />
            <RenderQuestionParameter
              infoText={question.parameters.infoText}
              additionalStyles={{ fontSize: "16px", padding: "8px 0px" }}
            />
            <CustomCheckBoxWithLabel question={question} />
          </Grid>
        </Grid>
      );
    case KYCQuestionType.Select:
      return (
        <RenderSelect
          question={question}
          options={mapCountryAnswers({
            filter: "Suomi",
            answers: question.answers,
            countries: undefined,
          })}
          key={keyProp}
          innerElement={innerElement}
          formState={formState}
          justifyFieldTo={justifyFieldTo}
          renderAlternativeQuestionTitle={renderAlternativeQuestionTitle}
        />
      );
    case KYCQuestionType.SelectionList:
      return (
        <RenderSelectionListQuestion
          question={question}
          options={mapCountryAnswers({
            filter: "Suomi",
            answers: question.answers,
            countries: undefined,
          })}
          key={keyProp}
          formState={formState}
        />
      );
    case KYCQuestionType.RadioButton:
      return (
        <Grid
          container
          style={{
            marginLeft: 0,
            marginRight: 0,
            padding: 0,
          }}
          key={keyProp}
        >
          <RenderQuestionParameter
            infoText={question.parameters.infoText}
            additionalStyles={{ fontSize: "18px", padding: "8px 0px" }}
          />
          <Grid item xs={12} md={12}>
            <Field
              name={question.name}
              component={renderRadio}
              required={true}
              id={`Radio_${keyProp}`}
            >
              {question.answers.map((answer, idx) => {
                return (
                  <FormControlLabel
                    key={`${answer.titleFI}${idx}`}
                    classes={{
                      label: "kyc-radio-label",
                    }}
                    value={answer.externalId}
                    control={
                      <Radio
                        icon={<CustomRadioIcon />}
                        checkedIcon={<CustomRadioIconChecked />}
                        id={`Radio_${keyProp}`}
                      />
                    }
                    label={question.titleFI}
                    aria-label={question.titleFI}
                  />
                );
              })}
            </Field>
          </Grid>
        </Grid>
      );
    case KYCQuestionType.NumberInput:
      return (
        <Grid container item lg={8} md={12} key={keyProp}>
          <ExtendedField
            id={question.name}
            component={renderTextfield}
            name={question.name}
            type="number"
            placeholder=""
            adornment={question.parameters.adornment}
            label={question.titleFI}
            onChange={(event: any) => {
              if (onlyChangeOnBlur) {
                event.preventDefault();
              }
            }}
            required={isRequiredMemoized()}
            aria-label={question.titleFI}
          />
        </Grid>
      );
    case KYCQuestionType.MultiPart:
      return (
        <Grid container key={keyProp}>
          <RenderQuestionParameter header={question.parameters.header} />
          <RenderQuestionParameter
            infoText={question.parameters.infoText}
            additionalStyles={{ marginTop: 30 }}
          />
          <Grid item xs={12}>
            <FieldArray
              name={question.name}
              component={(fieldArrayProps: any) => (
                <RenderMultipartList
                  fieldArrayProps={fieldArrayProps}
                  question={question}
                  kycFormProps={kycFormProps}
                />
              )}
            />
          </Grid>
        </Grid>
      );
    case KYCQuestionType.Date:
      return (
        <Grid
          container
          item
          key={keyProp}
          style={{
            justifyContent: justifyFieldTo,
          }}
        >
          <Grid item xs={8} md={8}>
            <ExtendedField
              id={question.name}
              component={renderTextfield}
              name={question.name}
              type="date"
              placeholder=""
              label={question.titleFI}
              format={(value: moment.MomentInput) => {
                return moment(value).format("YYYY-MM-DD").toString();
              }}
              required={isRequiredMemoized()}
              aria-label={question.titleFI}
            />
          </Grid>
        </Grid>
      );
    case KYCQuestionType.TextInput:
      return (
        <Grid
          container
          item
          key={keyProp}
          style={{
            justifyContent: justifyFieldTo,
          }}
        >
          {renderAlternativeQuestionTitle && (
            <RenderQuestionParameter
              infoText={question.parameters.infoText}
              additionalStyles={{ paddingBottom: "8px" }}
            />
          )}
          <Grid container item lg={8} md={12}>
            <ExtendedField
              id={question.name}
              component={renderTextfield}
              name={question.name}
              type="text"
              label={
                renderAlternativeQuestionTitle
                  ? question.parameters.alternativeQuestionTitle
                  : question.titleFI
              }
              onChange={(event: any) => {
                if (onlyChangeOnBlur) {
                  event.preventDefault();
                }
              }}
              required={isRequiredMemoized()}
              aria-label={question.titleFI}
            />
          </Grid>
          {innerElement}
        </Grid>
      );
    case KYCQuestionType.CountrySelect:
      return (
        <>
          {kycFormProps.countries && (
            <RenderSelect
              question={question}
              options={mapCountryAnswers({
                filter: "Suomi",
                answers: undefined,
                countries: kycFormProps.countries,
              })}
              key={keyProp}
              innerElement={innerElement}
              formState={formState}
              justifyFieldTo={justifyFieldTo}
              renderAlternativeQuestionTitle={renderAlternativeQuestionTitle}
            />
          )}
        </>
      );
    case KYCQuestionType.CountrySelectionList:
      return (
        <>
          {kycFormProps.countries && (
            <RenderSelectionListQuestion
              question={question}
              options={mapCountryAnswers({
                filter: "Suomi",
                answers: undefined,
                countries: kycFormProps.countries,
              })}
              key={keyProp}
            />
          )}
        </>
      );
    default:
      return <></>;
  }
};

export default RenderQuestionByType;
