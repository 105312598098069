import React from "react";
import LensIcon from "@mui/icons-material/Lens";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  lensIcon: {
    marginLeft: 5,
    color: theme.hypoColors.darkGrey,
    width: 6,
    height: 6,
  },
}));

const CustomLensIcon = () => {
  const { classes } = useStyles();
  return (
    <LensIcon
      aria-label="Pakollinen"
      aria-hidden={false}
      className={classes.lensIcon}
    />
  );
};

export default CustomLensIcon;
