import React from "react";
import { FormControlLabel } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CustomCheckboxIcon from "components/CustomCheckbox/CustomCheckboxIcon";
import CustomCheckboxIconChecked from "components/CustomCheckbox/CustomCheckboxIconChecked";
import CustomLensIcon from "components/icons/CustomLensIcon";
import ExtendedField from "../Form/ExtendedField";
import renderCheckBox from "./RenderCheckBox";
import { Question } from "types/models";

const useStyles = makeStyles()((theme) => ({
  label: {
    marginLeft: 10,
    fontFamily: theme.hypoFonts.gotham.book,
    fontSize: 16,
  },
}));

interface Props {
  question: Question;
}

const CustomCheckBoxWithLabel = (props: Props) => {
  const { question } = props;
  const { classes } = useStyles();

  return (
    <FormControlLabel
      control={
        <ExtendedField
          id={question.name}
          component={renderCheckBox}
          name={question.name}
          type="checkbox"
          normalize={(value: any) => !!value}
          customCheckboxIcon={<CustomCheckboxIcon />}
          customCheckboxIconChecked={<CustomCheckboxIconChecked />}
        />
      }
      label={
        <div className={classes.label}>
          {question.titleFI}
          {question.mandatory && (
            <span>
              <CustomLensIcon />
            </span>
          )}
        </div>
      }
    />
  );
};

export default CustomCheckBoxWithLabel;
