import React from "react";

export const ChevronIcon = (): JSX.Element => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 1L7 7L1 1" stroke="#FFFFFF" />
    </svg>
  );
};
