import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = (props: Props) =>
  makeStyles()((theme) => ({
    kycHeader: {
      fontWeight: 500,
      fontFamily: theme.hypoFonts.gotham.book,
      color: theme.hypoColors.darkGrey,
      marginBottom: 0,
      padding: "8px 0",
      fontSize: props.subHeader ? 16 : 18,
    },
  }));

interface Props {
  text: string;
  subHeader?: boolean;
  style?: React.CSSProperties;
}

const KycHeader = (props: Props) => {
  const { style, text } = props;
  const { classes } = useStyles(props)();
  return (
    <h4 className={classes.kycHeader} style={{ ...style }} tabIndex={0}>
      {text}
    </h4>
  );
};

export default KycHeader;
