import React, { useRef, useState } from "react";
import { useCombobox } from "downshift";
import { Input, Button, List } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ChevronIcon } from "./icons";

const useStyles = makeStyles<StyleParams>()((theme, { isSideNav }) => ({
  input: {
    width: "auto",
  },
  innerInput: {
    fontSize: "16px",
    fontFamily: theme.hypoFonts.gotham.book,
    color: isSideNav ? "#ffffff" : "#646363",
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04);",
    },
  },
  whiteBackground: {
    backgroundColor: "#FFFFFF",
  },
  label: {
    fontFamily: theme.hypoFonts.gotham.book,
    fontSize: "10px",
    color: isSideNav ? "#ffffff" : "#646363",
  },
  dropdownContainer: {
    position: "absolute",
    boxShadow: "0px 2px 5px 2px rgba(0,0,0, 0.3)",
    zIndex: 999,
  },
  listItem: {
    fontFamily: theme.hypoFonts.gotham.book,
    fontSize: "16px",
    padding: "5px 2px 5px 2px",
    minHeight: "10px",
  },
  comboBoxInputUnderline: {
    "&:before": {
      borderBottom: isSideNav ? "1px solid #ffffff" : "1px solid #92c9f0",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: isSideNav ? "2px solid #ffffff" : "2px solid #92c9f0",
    },
    "&:after": {
      borderBottom: isSideNav ? "2px solid #ffffff" : "2px solid #0069b4",
    },
    backgroundColor: isSideNav ? "transparent" : "#FFFFFF",
  },
  noPadding: {
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

export function defaultItemToString(value: unknown | null): string {
  return value ? String(value) : "";
}

interface StyleParams {
  isSideNav: boolean | undefined;
}

interface InputBoxOptions<T> {
  items: T[];
  itemToString: (value: T | null) => string;
  label?: string;
  placeholder?: string;
  showButton?: boolean;
  onInputValueChange?: (value: string) => void;
  onSelectedItemChange?: (item: T | null) => void;
  useAsInputOnly?: boolean;
  initialSelectedItem?: T;
  "data-testid"?: string;
  isSideNav?: boolean;
}

export default function ComboBox<T>(props: InputBoxOptions<T>): JSX.Element {
  const {
    items,
    label,
    showButton = true,
    useAsInputOnly = false,
    initialSelectedItem,
    itemToString,
    isSideNav,
  } = props;
  const { classes } = useStyles({ isSideNav: isSideNav });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputItems, setInputItems] = useState(items);

  React.useEffect(() => setInputItems(items), [items]);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    selectedItem,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    initialSelectedItem,
    itemToString,
    onSelectedItemChange: (_changes) => {
      // Ensure undefined and null both get normalized into just null
      props.onSelectedItemChange?.(_changes.selectedItem ?? null);
    },
    onInputValueChange: ({ inputValue }) => {
      const inputLower = inputValue?.toLocaleLowerCase() ?? "";
      setInputItems(
        items.filter((item) => {
          return itemToString(item).toLocaleLowerCase().startsWith(inputLower);
        })
      );
      props.onInputValueChange && props.onInputValueChange(inputValue ?? "");
    },
  });

  return (
    <div data-testid={props["data-testid"]}>
      <label className={classes.label} {...getLabelProps()}>
        {label}
      </label>
      <div>
        <Input
          ref={inputRef}
          fullWidth
          className={classes.comboBoxInputUnderline}
          endAdornment={
            showButton && (
              <Button
                className={classes.button}
                {...getToggleButtonProps()}
                aria-label={"toggle menu"}
              >
                <ChevronIcon />
              </Button>
            )
          }
          inputProps={{
            className: classes.innerInput,
            "aria-label": "combobox input",
          }}
          placeholder={props.placeholder}
          {...getInputProps({ refKey: "inputRef" })}
        />
      </div>
      <List
        className={
          !useAsInputOnly && isOpen
            ? `${classes.dropdownContainer} ${classes.whiteBackground}`
            : `${classes.noPadding}`
        }
        {...getMenuProps()}
        style={{ width: inputRef?.current?.clientWidth }}
      >
        {!useAsInputOnly &&
          isOpen &&
          inputItems.map((item, index) => (
            <li
              key={`${item}${index}`}
              className={classes.listItem}
              style={{
                backgroundColor:
                  highlightedIndex === index ? "#bde4ff" : "#FFFFFF",
                fontWeight: selectedItem === item ? "bold" : "normal",
              }}
              {...getItemProps({ item, index })}
            >
              {itemToString(item)}
            </li>
          ))}
      </List>
    </div>
  );
}
