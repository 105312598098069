import React from "react";
import { BaseFieldProps, Field, GenericFieldHTMLAttributes } from "redux-form";
import { Common } from "./Common";

interface SelectOption {
  label: string;
  value: string | number;
  header?: boolean;
}

export interface CustomLabelStyles {
  color: string;
  fontSize: string | number;
}

interface ExtendedFieldProps {
  minCount?: number;
  maxCount?: number;
  adornment?: string | React.ReactNode;
  showCount?: boolean;
  onExtraBlur?: Function;
  multiline?: boolean;
  options?: SelectOption[];
  labelcase?: "lower" | "upper";
  name?: string;
  value?: string;
  checked?: string | boolean;
  valueSelected?: string;
  required?: boolean;
  isAutoComplete?: boolean;
  isAutosuggest?: boolean;
  rowsMax?: number;
  labelStyles?: CustomLabelStyles;
  customSelectClasses?: string;
  customCheckboxIcon?: React.ReactNode;
  customCheckboxIconChecked?: React.ReactNode;
  renderEmptyCellItem?: boolean;
  inputProps?:
    | React.TextareaHTMLAttributes<HTMLTextAreaElement>
    | React.InputHTMLAttributes<HTMLInputElement>;
}

type Props = BaseFieldProps<Common> &
  GenericFieldHTMLAttributes &
  ExtendedFieldProps & { label?: string };

const ExtendedField = Field as new () => Field<Props>;

export default ExtendedField;
