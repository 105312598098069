import React from "react";
import { WrappedFieldProps } from "redux-form";
// import LensIcon from "@mui/material/Icon";
import { Common } from "./Common";
import CustomTextInput from "./CustomTextInput";

interface ExtendedFieldProps {
  minCount?: number;
  maxCount?: number;
  adornment?: string;
  inputProps?:
    | React.TextareaHTMLAttributes<HTMLTextAreaElement>
    | React.InputHTMLAttributes<HTMLInputElement>;
  showCount?: boolean;
  onExtraBlur?: Function;
  // required?: boolean;
}

const renderTextfield = (
  props: Common & WrappedFieldProps & ExtendedFieldProps
) => {
  const {
    input,
    meta,
    style,
    adornment,
    inputProps,
    label,
    meta: { touched, error },
    required,
    ...nonprops
  } = props;

  let t = style || {};

  const styles = {
    ...t,
    fontSize: "1.35em",
  };

  return (
    <CustomTextInput
      {...nonprops}
      {...input}
      label={label || ""}
      adornment={adornment}
      style={styles}
      error={touched && error}
      fullWidth={true}
      inputProps={inputProps}
      required={required}
      endAdornment={adornment}
    />
  );
};

export default renderTextfield;
