import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { makeStyles } from "tss-react/mui";
import { AuthenticationInfo } from "types/AuthenticationInfo";

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    overflowX: "hidden",
  },
  cardContainer: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #DCDCDC",
    boxSizing: "border-box",
    margin: "25px 0",
    display: "inline-grid",
    width: "100%",
    overflowX: "hidden",
  },
  card: {
    padding: "0 20px",
    height: 84,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));

const correctBank = (authenticationInfo: AuthenticationInfo) => {
  switch (authenticationInfo.id) {
    case "oidc.aktia.1":
      return <img src={"./BankLogos/Aktia.png"} alt="aktia" />;
    case "oidc.alandsbanken.1":
      return <img src={"./BankLogos/alands.png"} alt="alands" />;
    case "oidc.danskebank.1":
      return <img src={"./BankLogos/danskebank.png"} alt="danskebank" />;
    case "oidc.handelsbanken.1":
      return <img src={"./BankLogos/handels.png"} alt="handelsbanken" />;
    case "saml.op.1":
      return <img src={"./BankLogos/op.png"} alt="op" />;
    case "saml.op.fi":
      return <img src={"./BankLogos/op.png"} alt="op" />;
    case "oidc.nordea.1":
      return <img src={"./BankLogos/nordea.png"} alt="nordea" />;
    case "oidc.omasp.1":
      return <img src={"./BankLogos/omasp.png"} alt="omasp" />;
    case "oidc.spankki.1":
      return <img src={"./BankLogos/spankki.png"} alt="spankki" />;
    case "oidc.sp.1":
      return <img src={"./BankLogos/sp.png"} alt="sp" />;
    case "oidc.pop.1":
      return <img src={"./BankLogos/pop.png"} alt="pop" />;
    default:
      return <></>;
  }
};

const BankList = () => {
  const matchesMediaQuery = useMediaQuery("(min-width: 600px)");
  const matchesMediaQueryHeight = useMediaQuery("(min-height: 730px)");

  const authenticationInfo = useSelector(
    (state: RootState) => state.authenticationInfo.data,
    shallowEqual
  );

  const authenticationInfoLoading = useSelector(
    (state: RootState) => state.authenticationInfo.loading
  );

  const mobileHeight = authenticationInfoLoading
    ? "calc(100vh - 230px)"
    : matchesMediaQueryHeight
    ? "calc(100vh - 230px)"
    : "auto";

  const classes = useStyles().classes;

  return (
    <div
      className={classes.container}
      style={{
        alignItems: "center",
        height: matchesMediaQuery ? "calc(100% - 270px)" : mobileHeight,
      }}
    >
      <div
        style={{
          width: "fit-content",
          height: "fit-content",
          maxWidth: 750,
          textAlign: "center",
          margin: 16,
        }}
      >
        {authenticationInfoLoading ? (
          <div style={{ padding: "36px 0 20px 0" }}>
            <div />
          </div>
        ) : (
          <>
            <div
              className={classes.cardContainer}
              style={{
                gridTemplateColumns: matchesMediaQuery
                  ? "1fr 1fr 1fr 1fr"
                  : "1fr 1fr",
                height: matchesMediaQuery ? "auto" : 300,
              }}
            >
              {authenticationInfo?.map((a, i) => (
                <Grid key={a.id} container>
                  <Grid>
                    <div
                      onClick={() => {
                        window.location.assign(a.url);
                      }}
                      className={classes.card}
                      style={{
                        borderRight:
                          (i + 1) % 4 === 0 ? undefined : "1px solid #DCDCDC",
                        borderBottom:
                          i + 1 > 8 ? undefined : "1px solid #DCDCDC",
                      }}
                    >
                      {correctBank(a)}
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(BankList);
