import {
  PayloadAction,
  SerializedError,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Country } from "types/interfaces";
import { getAllCountries } from "./countries/countries-actions";

export interface CountriesState {
  loading: boolean;
  error: SerializedError | null;
  all: Country[];
}

const initialState: CountriesState = {
  loading: true,
  error: null,
  all: [],
};

export const countriesSlice = createSlice({
  name: "countries",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCountries.pending, (state, _action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllCountries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(
      getAllCountries.fulfilled,
      (state, action: PayloadAction<Country[] | undefined>) => {
        state.loading = false;
        if (action.payload !== undefined) {
          state.all = action.payload;
        }
      }
    );
  },
});

export const getCountriesSelector = createSelector(
  (state: RootState): Country[] => state.countries.all,
  (it) => it
);
