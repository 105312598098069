import React from "react";
import KycHeader from "./KycHeader";
import InfoText from "./InfoText";

interface Props {
  header?: string | undefined;
  subHeader?: string | undefined;
  infoText?: string | undefined;
  secondInfoText?: string | undefined;
  additionalStyles?: React.CSSProperties | undefined;
}

const RenderQuestionParameter = (props: Props) => {
  const { additionalStyles, header, infoText, secondInfoText, subHeader } =
    props;
  return (
    <>
      {header && <KycHeader text={header} style={{ ...additionalStyles }} />}
      {subHeader && (
        <KycHeader text={subHeader} style={{ ...additionalStyles }} />
      )}
      {infoText && <InfoText text={infoText} style={{ ...additionalStyles }} />}
      {secondInfoText && (
        <InfoText text={secondInfoText} style={{ ...additionalStyles }} />
      )}
    </>
  );
};

export default RenderQuestionParameter;
