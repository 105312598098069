/* tslint:disable no-any */
import React, { useState } from "react";
import { Input, InputAdornment, InputProps, FormControl } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import CustomLensIcon from "components/icons/CustomLensIcon";

const useStyles = makeStyles()((theme) => ({
  input: {
    marginTop: 10,
    fontSize: 14,
    fontFamily: theme.hypoFonts.gotham.book,
    "&:focus": {
      background: "rgba(0, 0, 0, 0.05)",
    },
  },
  label: {
    marginTop: 5,
    color: theme.hypoColors.darkGrey,
    fontFamily: theme.hypoFonts.gotham.book,
    fontSize: 11,
  },
}));

interface IExtendedInputProps extends InputProps {
  delay?: number;
  onChangeUsesEvent?: boolean;
  label?: string;
  adornment?: string;
  required?: boolean;
  isAutosuggest?: boolean;
  isAutoComplete?: boolean;
  onChangeCapture?: () => {};
}

const CustomTextInput = (props: IExtendedInputProps) => {
  const {
    onChange,
    delay,
    value,
    adornment,
    onBlur,
    onChangeUsesEvent,
    isAutosuggest,
    isAutoComplete,
    type,
    error,
    label,
    required,
    ...args
  } = props;
  const { classes } = useStyles();
  const [stateValue, setStateValue] = useState(value);

  const handleOnChange = (event: any) => {
    event.preventDefault();

    setStateValue(event.target.value);
  };

  const handleBlur = (_event: any) => {
    if (onBlur) {
      onBlur(_event);
    }
  };

  return (
    <FormControl error={error} style={{ width: "100%" }}>
      <Input
        {...args}
        onInput={handleOnChange}
        onBlur={handleBlur}
        error={!!error}
        classes={{
          input: classes.input,
        }}
        type={type}
        value={stateValue}
        endAdornment={
          <InputAdornment classes={{ root: "adornment-bold" }} position="end">
            {adornment}
          </InputAdornment>
        }
      />
      <label htmlFor={args.id} className={classes.label}>
        {label}
        {required && <CustomLensIcon />}
        <br />
        <span style={{ color: "red" }}>{error}</span>
      </label>
    </FormControl>
  );
};

CustomTextInput.defaultProps = {
  label: "",
  delay: 200,
  adornment: "",
  required: false,
  isAutosuggest: false,
  isAutoComplete: false,
};

export default CustomTextInput;
