import React from "react";
import { Grid, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  container: {
    marginBottom: "15px",
    marginTop: "50px",
  },
  flex: {
    display: "flex",
  },
  leftContainer: {
    justifyContent: "flex-start",
  },
  rightContainer: {
    justifyContent: "flex-end",
  },
  button: {
    padding: "7px",
  },
  buttonLeft: {
    border: "1px solid #92C9F0",
    backgroundColor: "#FFFFFF",
    color: "#646363",
    minWidth: "90px",
  },
  buttonRight: {
    border: "1px solid #004170",
    backgroundColor: "#004170",
    color: "#FFFFFF",
    minWidth: "90px",
  },
});

interface BottomRowProps {
  leftButtonLabel: string;
  rightButtonLabel: string;
  onLeftButtonClick?: (
    event: React.MouseEvent<SVGSVGElement | HTMLButtonElement, MouseEvent>
  ) => void;
  onRightButtonClick?: () => void;
  leftButtonDisabled?: boolean;
  rightButtonDisabled?: boolean;
}

export default function BottomRow(props: BottomRowProps): JSX.Element {
  const { classes } = useStyles();
  const {
    leftButtonLabel,
    rightButtonLabel,
    onLeftButtonClick,
    onRightButtonClick,
  } = props;
  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} className={`${classes.flex} ${classes.leftContainer}`}>
        <Button
          className={`${classes.button} ${classes.buttonLeft}`}
          onClick={onLeftButtonClick}
          disabled={props.leftButtonDisabled}
        >
          {leftButtonLabel}
        </Button>
      </Grid>
      <Grid item xs={6} className={`${classes.flex} ${classes.rightContainer}`}>
        <Button
          className={`${classes.button} ${classes.buttonRight}`}
          onClick={onRightButtonClick}
          disabled={props.rightButtonDisabled}
        >
          {rightButtonLabel}
        </Button>
      </Grid>
    </Grid>
  );
}
