import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddCompanyDataWithIdDto } from "models/AddCompanyDataWithIdDto";
import { InterestedPartyDto } from "models/InterestedPartyDto";
import { UserCompanyDto } from "models/UserCompanyDto";
import { createCustomAction } from "typesafe-actions";
import * as request from "utils/request";

const apiUrl = process.env.REACT_APP_API_URL;

export const emptyCompaniesActionType = "companies/empty";

export const empty = createCustomAction(emptyCompaniesActionType);

export const fetchCompanies = createAsyncThunk(
  "companies/fetch-companies",
  async (userId: string) => {
    try {
      const response = await request.getResponseJson<UserCompanyDto[]>(
        `${apiUrl}/api/company/allbyssn/`
      );

      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const addCompany = createAsyncThunk(
  "companies/add-company",
  async (data: AddCompanyDataWithIdDto) => {
    try {
      const response = await request.postAndReturnResponseJson<
        UserCompanyDto[]
      >(`${apiUrl}/api/company/addCompany`, data);

      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

export const getCompanyByNumber = createAsyncThunk(
  "companies/get-company-by-number",
  async (businessNumber: string) => {
    try {
      const response = await request.getResponseJson<InterestedPartyDto>(
        `${apiUrl}/api/company/getbynumber/${businessNumber}`
      );

      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const removeCompaniesState = empty;
