export const validateFreeTextInput = (
  value: string | undefined,
  inputLengthValidationMessageStart: string,
  inputMaxLength: number,
  isRequired: boolean
) => {
  const requiredMessage = isRequired ? required(value) : undefined;
  return (
    requiredMessage ||
    checkForSpecialChars(value) ||
    (value &&
      validInputLength(
        value,
        inputMaxLength,
        inputLengthValidationMessageStart
      ))
  );
};

const checkForSpecialChars = (
  v: string | undefined,
  message = "Virheellinen syöte"
) => {
  const re = /^[a-zA-Z0-9_äÄöÖåÅ ]+$/;
  return re.test(v ?? "") ? undefined : message;
};

export function validInputLength(
  v: string,
  maxLength: number,
  messageStart: string,
  message = `${messageStart} enimmäispituus on ${maxLength} merkkiä`
) {
  return v.length > maxLength ? message : undefined;
}

export function validNumberInput(
  v: number | undefined,
  message = "Syötetty luku on liian suuri"
) {
  if (!v) {
    return undefined;
  }
  return v > 2147483647 ? message : undefined;
}

export function validTaxationNumber(
  v: string | undefined,
  message = "Veronumero ei saa sisältää erikoismerkkejä, kirjoita veronumero ilman erikoismerkkejä"
) {
  if (!v) {
    return "Pakollinen";
  }
  if (v.length > 20) {
    return "Veronumeron enimmäispituus on 20 merkkiä";
  }
  const regex = /^[a-zA-Z0-9\-()/+]*$/i;
  return regex.test(v) ? undefined : message;
}

export function required<T>(v: T, message = "Pakollinen") {
  return v ? undefined : message;
}

export function equal<T>(
  expected: T,
  actual: T,
  message = "Arvot eivät täsmää"
) {
  return expected === actual ? undefined : message;
}

export function validEmail(
  v: string,
  message = "Virheellinen sähköpostiosoite"
) {
  if (v.length > 60) {
    return "Sähköpostin enimmäispituus on 60 merkkiä";
  }
  const regex =
    /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
  const mail = String(v || "").toLowerCase();

  return regex.test(mail) ? undefined : message;
}

export function validPassword(
  v: string | null | undefined,
  message = "Salasanan tulee olla 12-64 merkkiä pitkä"
) {
  if (v) {
    return v.length < 12 || v.length > 64 ? message : undefined;
  }

  return required(v);
}

export function numberBetween(
  value: number | string,
  maxValue: number,
  minValue: number,
  message: string
) {
  if (!isNumericString(String(value))) {
    return "Virheellinen luku";
  }

  if (Number(value) > Number(maxValue) || Number(value) < Number(minValue)) {
    return message;
  }

  return undefined;
}

export function lessThanOrEqual(
  benchmark: number,
  value: number,
  message: string
) {
  if (!isNumericString(String(benchmark)) || !isNumericString(value)) {
    return "Virheellinen luku";
  }

  if (Number(value) > Number(benchmark)) {
    return message;
  }
  return undefined;
}

export function isNumeric(
  value: string | number | null | undefined,
  message: string | null = null,
  minLength: number | null = null,
  maxLength: number | null = null,
  hasToBePositive: boolean = false
) {
  if (value === undefined || value === "" || value === null) {
    return undefined;
  }

  if (isNumericString(value, minLength, maxLength)) {
    if (hasToBePositive && String(value).includes("-")) {
      return message
        ? message
        : hasToBePositive
        ? "Positiivinen luku vaadittu"
        : "Virheellinen luku";
    }
    return undefined;
  }
  return message
    ? message
    : hasToBePositive
    ? "Positiivinen luku vaadittu"
    : "Virheellinen luku";
}

function isNumericString(
  str: string | number,
  minLength: number | null = null,
  maxLength: number | null = null
) {
  if (typeof str === "string") {
    if (
      !str ||
      str.length === 0 ||
      (minLength !== null && str.length < minLength) ||
      (maxLength !== null && str.length > maxLength)
    ) {
      return false;
    }

    if (String(str).includes("e")) {
      return false;
    }

    // Allow commas
    str = str.replace(/,/g, ".");

    // Force convert to number
    const cvrt = Number(str);
    return !isNaN(cvrt);
  } else if (typeof str === "number") {
    if (String(str).includes("e")) {
      return false;
    }
    return true;
  }
  return false;
}
