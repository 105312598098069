import React from "react";
import { Grid } from "@mui/material";
import { RouteDescription } from "types/models";
import NavigationLink from "./NavigationLink";
import { PrivateRoutesEnum, RoutesEnum } from "App";

type NavigationLinksProps = {
  routes?: RouteDescription[];
};

export const NavigationLinks: React.FC<NavigationLinksProps> = (
  props: NavigationLinksProps
) => {
  const routes = props.routes ?? [];
  return (
    <Grid container>
      {routes.map((route, index) => {
        return route.to === RoutesEnum.Root ||
          route.to === PrivateRoutesEnum.ChooseCompany ||
          route.to === RoutesEnum.Login ||
          route.to === RoutesEnum.Logout ||
          route.to === RoutesEnum.Error ? (
          <div key={index}></div>
        ) : (
          <NavigationLink key={index} route={route} />
        );
      })}
    </Grid>
  );
};

export default NavigationLinks;
