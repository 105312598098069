import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    margin: "50px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginBottom: "150px",
    width: "65%",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "65%",
    width: "65%",
  },
  errorTextHeader: {
    fontFamily: theme.hypoFonts.gotham.bold,
    fontSize: "2em",
    marginBottom: "0.5em",
  },
  errorText: {
    fontFamily: theme.hypoFonts.gotham.book,
    fontSize: "1.5em",
  },
}));

export const ErrorPage = () => {
  const { classes } = useStyles();

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid container item xs={8} className={classes.container}>
        <Typography variant="h1" className={classes.errorTextHeader}>
          Tapahtui virhe
        </Typography>
        <Typography variant="h4" className={classes.errorText}>
          Ole hyvä ja siirry palvelun <a href="/">etusivulle</a> tai yritä
          myöhemmin uudelleen.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(ErrorPage);
