import React from "react";

const CustomRadioIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5 12.5C23.5 19.1469 18.3322 24.5 12 24.5C5.66777 24.5 0.5 19.1469 0.5 12.5C0.5 5.85315 5.66777 0.5 12 0.5C18.3322 0.5 23.5 5.85315 23.5 12.5Z"
        stroke="#0069B4"
      />
    </svg>
  );
};

export default CustomRadioIcon;
