import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { RouteDescription } from "types/models";
// import { EuroIcon, MailIcon, UserIcon, BinocularsIcon } from "../icons";
import clsx from "clsx";

const useStyles = makeStyles()((theme) => ({
  selected: {
    fontWeight: "bold",
  },
  withPadding: {
    paddingLeft: "15px",
  },
  navLink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.hypoColors.darkGrey,
    fontFamily: theme.hypoFonts.gotham.book,
    fontStyle: "normal",
    fontSize: "18px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  subNavLink: {
    paddingLeft: "0px",
  },
  linkContainer: {
    padding: "5px",
  },
}));

interface NavigationLinkProps {
  route: RouteDescription;
}

export default function NavigationLink(
  props: NavigationLinkProps
): JSX.Element {
  const { classes } = useStyles();
  const location = useLocation();
  const { route } = props;
  const isActive = location.pathname === route.to;
  const isSubRouteActive = route.subRoutes?.some((sub) => {
    return location.pathname === sub.to;
  });
  return (
    <Grid item xs={12} key={route.title} className={classes.linkContainer}>
      <NavLink
        end
        to={route.to}
        className={({ isActive }) =>
          clsx({
            [classes.navLink]: true,
            [classes.selected]: isActive || isSubRouteActive,
          })
        }
      >
        {/* {getIconForRoute(route.to, isActive || isSubRouteActive)} */}
        {route.navTitle ?? route.title}
      </NavLink>
      {(isActive || isSubRouteActive) &&
        route.subRoutes &&
        route.subRoutes.map((sub, index) => {
          return (
            <Grid container key={`grid_${index}`}>
              <Grid item xs={4}></Grid>
              <Grid item xs>
                <NavLink
                  end
                  to={sub.to}
                  className={({ isActive }) =>
                    clsx({
                      [classes.navLink]: true,
                      [classes.selected]: isActive,
                    })
                  }
                >
                  {sub.navTitle ?? sub.title}
                </NavLink>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
}

// const getIconForRoute = (
//   path: string,
//   isActive: boolean | undefined
// ): JSX.Element | null => {
//   const style: React.CSSProperties = { marginRight: "8px" };

//   switch (path) {
//     case "signatures":
//       return <EuroIcon isActive={isActive} style={style} />;
//     case "/":
//       return <MailIcon isActive={isActive} style={style} />;
//     case "usermanagement":
//       return <UserIcon isActive={isActive} style={style} />;
//     case "audittrail":
//       return <BinocularsIcon isActive={isActive} style={style} />;
//     default:
//       return null;
//   }
// };
