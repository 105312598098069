export enum KYCQuestionType {
  Select,
  RadioButton,
  CheckBox,
  TextInput,
  NumberInput,
  SelectionList,
  Date,
  MultiPart,
  CountrySelect,
  CountrySelectionList,
}

export enum DynamicFormAPI {
  KYC,
  IP,
}

export enum PopupType {
  OneTimeCode = "onetimecode",
}

export enum SideNavComboboxData {
  AddNewCompany = "Lisää uusi yritys",
}

export enum KYCSaveStatusEnum {
  None,
  Success,
  Failed,
}

export enum KYCSaveStatusMessage {
  None = "",
  Success = "Tallennus onnistui",
  Failed = "Tallennus epäonnistui. Yritä myöhemmin uudelleen.",
}
