import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { AuthInfo } from "types/models";
import { RootState } from "./store";

export type UserState = {
  info: AuthInfo | null;
};

const initialState: UserState = {
  info: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<AuthInfo>) => {
      state.info = action.payload;
    },
  },
});

export const getUserInfoSelector = createSelector(
  (state: RootState): AuthInfo | null => state.user.info,
  (it) => it
);

export const { setUserInfo } = userSlice.actions;
