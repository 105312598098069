import {
  PayloadAction,
  SerializedError,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addCompany,
  emptyCompaniesActionType,
  fetchCompanies,
  getCompanyByNumber,
} from "./companies/companies-actions";
import { RootState } from "./store";
import { InterestedPartyDto } from "models/InterestedPartyDto";
import { UserCompanyDto } from "models/UserCompanyDto";

export interface CompaniesState {
  addCompanyLoading: boolean;
  companiesLoading: boolean;
  currentLoading: boolean;
  error: SerializedError | null;
  all: UserCompanyDto[];
  current: InterestedPartyDto | null;
}

const initialState: CompaniesState = {
  addCompanyLoading: false,
  companiesLoading: false,
  currentLoading: false,
  error: null,
  all: [],
  current: null,
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState: initialState,
  reducers: {
    removeCompaniesState: (state) => {
      state.current = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.pending, (state, _action) => {
      state.companiesLoading = true;
      state.error = null;
    });
    builder.addCase(fetchCompanies.rejected, (state, action) => {
      state.companiesLoading = false;
      state.error = action.error;
    });
    builder.addCase(
      fetchCompanies.fulfilled,
      (state, action: PayloadAction<UserCompanyDto[] | undefined>) => {
        state.companiesLoading = false;
        if (action.payload !== undefined) {
          state.all = action.payload;
        }
      }
    );
    builder.addCase(addCompany.pending, (state, _action) => {
      state.addCompanyLoading = true;
      state.error = null;
    });
    builder.addCase(addCompany.rejected, (state, action) => {
      state.addCompanyLoading = false;
      state.error = action.error;
    });
    builder.addCase(addCompany.fulfilled, (state, action) => {
      state.addCompanyLoading = false;
      if (action.payload !== undefined) {
        state.all = action.payload;
      }
    });
    builder.addCase(getCompanyByNumber.pending, (state, _action) => {
      state.currentLoading = true;
      state.error = null;
    });
    builder.addCase(getCompanyByNumber.rejected, (state, action) => {
      state.currentLoading = false;
      state.error = action.error;
    });
    builder.addCase(
      getCompanyByNumber.fulfilled,
      (state, action: PayloadAction<InterestedPartyDto | undefined>) => {
        state.currentLoading = false;
        if (action.payload !== undefined) {
          state.current = action.payload;
        }
      }
    );
    builder.addCase(emptyCompaniesActionType, (state) => {
      state = initialState;
    });
  },
});

export const getCompaniesSelector = createSelector(
  (state: RootState): UserCompanyDto[] => state.companies.all,
  (it) => it
);

export const getCurrentCompanySelector = createSelector(
  (state: RootState): InterestedPartyDto | null => state.companies.current,
  (it) => it
);

export const selectCurrentLoading = createSelector(
  (state: RootState): boolean => state.companies.currentLoading,
  (it) => it
);

export const { removeCompaniesState } = companiesSlice.actions;
