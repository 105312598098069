import { BaseFieldProps, Field, GenericFieldHTMLAttributes } from "redux-form";
import { makeStyles } from "tss-react/mui";
import { Common } from "./Common";

const useStyles = makeStyles()((theme) => ({
  kycSelect: {
    width: "100%",
    "& + label": {
      marginTop: 10,
      color: theme.hypoColors.darkGrey,
      fontFamily: theme.hypoFonts.gotham.book,
      fontSize: 11,
    },
    "& .MuiOutlinedInput-input": {
      padding: "5px 0 5px 1px",
      fontFamily: theme.hypoFonts.gotham.book,
      "&:focus": {
        background: "rgba(0, 0, 0, 0.05)",
      },
    },
  },
  interval: {
    height: 38.6,
    "& select": {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: theme.hypoColors.darkGrey,
      height: "1.25em",
    },
  },
  hyposelectArrowKyc: {
    "& select + svg": {
      color: theme.hypoColors.darkGrey,
      transform: "scale(1.5)",
    },
  },
}));

interface SelectOption {
  label: string;
  value: string | number;
  header?: boolean;
}

interface CustomExtendedFieldProps {
  onChangeCapture?: (event: any) => void;
  options?: SelectOption[];
  renderEmptyCellItem?: boolean;
  adornment?: string;
}

type Props = BaseFieldProps<Common> &
  GenericFieldHTMLAttributes &
  CustomExtendedFieldProps & { label?: string };

const ExtendedField = Field as new () => Field<Props>;

const CustomExtendedField = (props: Props) => {
  const { classes } = useStyles();
  return (
    <ExtendedField
      {...props}
      className={`${classes.kycSelect} ${classes.hyposelectArrowKyc}`}
    />
  );
};

export default CustomExtendedField;
