import React from "react";

const CustomCheckboxIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="25" height="25" rx="1.5" stroke="#0069B4" />
    </svg>
  );
};

export default CustomCheckboxIcon;
