import React from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Button } from "@mui/material";
import { Question } from "types/models";
import { KYCFormProps } from "./DynamicKYCForm";
import RenderQuestionByType from "./RenderQuestionByType";

interface Props {
  fieldArrayProps: any;
  question: Question;
  kycFormProps: KYCFormProps;
}

const useStyles = makeStyles()((theme) => ({
  addButton: {
    fontSize: "clamp(0.75em, 0.55vw, 0.55vw)",
    minHeight: "54px",
    minWidth: 0,
    [theme.breakpoints.down("md")]: {
      height: "35px",
      minHeight: 0,
    },
  },
  listItem: {
    padding: "1em 0",
    [theme.breakpoints.down("md")]: {
      borderTop: `2px solid #e2e1e1`,
    },
  },
  removeButton: {
    fontSize: "clamp(0.75em, 0.55vw, 0.55vw)",
    minHeight: "54px",
    minWidth: 0,
    width: "95%",
    [theme.breakpoints.down("md")]: {
      height: "35px",
      minHeight: 0,
      width: "100%",
      marginBottom: "1em",
    },
  },
}));

const RenderMultipartList = (props: Props) => {
  const { classes } = useStyles();
  const { fieldArrayProps, kycFormProps, question } = props;

  if (fieldArrayProps.fields.length === 0) {
    fieldArrayProps.fields.push();
  }

  return (
    <ul
      aria-live="polite"
      style={{
        listStyle: "none",
        paddingLeft: 0,
        margin: 0,
      }}
    >
      {fieldArrayProps.fields &&
        fieldArrayProps.fields.map((country: any, key: any) => {
          const additionalQuestions: Question[] = JSON.parse(
            JSON.stringify(question.additionalQuestions)
          );

          if (key !== 0) {
            return (
              <li key={key} className={classes.listItem}>
                <Grid
                  container
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {additionalQuestions &&
                    additionalQuestions.map((q, index) => {
                      q.name = `${country}.${q.name}`;
                      return (
                        <RenderQuestionByType
                          question={q}
                          kycFormProps={kycFormProps}
                          key={`${q.name}_sub_${index}`}
                          innerElement={
                            index === 0 ? (
                              <Grid
                                container
                                item
                                lg={4}
                                md={12}
                                justifyContent="flex-end"
                                alignContent="center"
                              >
                                <Button
                                  className={classes.removeButton}
                                  color="primary"
                                  fullWidth={true}
                                  variant="text"
                                  onClick={() =>
                                    fieldArrayProps.fields.remove(key)
                                  }
                                >
                                  {question.parameters.removeButtonTitle}
                                </Button>
                              </Grid>
                            ) : null
                          }
                          onlyChangeOnBlur
                          isMultipartQuestion
                          isMultipartQuestionFirstField={index === 0}
                        />
                      );
                    })}
                </Grid>
              </li>
            );
          }
          return (
            <li key={key} style={{ paddingBottom: "1em" }}>
              <Grid
                container
                style={{
                  padding: 0,
                  margin: 0,
                }}
              >
                {additionalQuestions &&
                  additionalQuestions.map((q, index) => {
                    q.name = `${country}.${q.name}`;
                    return (
                      <RenderQuestionByType
                        question={q}
                        kycFormProps={kycFormProps}
                        key={`add_${index}`}
                        innerElement={null}
                        onlyChangeOnBlur
                        isMultipartQuestion
                        isMultipartQuestionFirstField={index === 0}
                      />
                    );
                  })}
              </Grid>
            </li>
          );
        })}
      <li>
        <Grid container>
          <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
            <Button
              id={`${question.name}Button`}
              className={classes.addButton}
              color="primary"
              fullWidth={true}
              variant="text"
              onClick={() => {
                fieldArrayProps.fields.push();
              }}
              aria-label={question.parameters.addButtonTitle}
            >
              {question.parameters.addButtonTitle}
            </Button>
          </Grid>
        </Grid>
      </li>
    </ul>
  );
};

export default RenderMultipartList;
