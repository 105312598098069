import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { blue } from "@mui/material/colors";
import { RoutesEnum } from "App";
import React from "react";
import { useNavigate } from "react-router-dom";

type LoginButtonProps = {
  text?: string;
  icon?: {};
  disabled: boolean;
  showSpinner: boolean;
  className?: string | "";
};

export const LoginButton: React.FC<LoginButtonProps> = (
  props: LoginButtonProps
) => {
  const navigate = useNavigate();

  return (
    <Button
      className={props.className}
      color="primary"
      variant="text"
      onClick={() => navigate(RoutesEnum.Identify)}
      style={{
        minHeight: 54,
        minWidth: 0,
        width: "80%",
      }}
      disabled={props.disabled}
      aria-label={props.text || ""}
    >
      <>
        {props.text || ""}
        {props.icon}
        {props.showSpinner && props.disabled && (
          <CircularProgress
            size={24}
            style={{
              color: blue[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: -12,
              marginLeft: -12,
            }}
          />
        )}
      </>
    </Button>
  );
};

export default LoginButton;
