import { Grid } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import DynamicKYCForm from "components/Form/DynamicKYCForm";
import React, { useEffect } from "react";
import { getAllCountries } from "redux/countries/countries-actions";
import {
  initialKycDataSelector,
  kycFormSelector,
  setInitialKycData,
  setKycSaveStatus,
} from "redux/kyc";
import { getKYCForm, saveKYCData } from "redux/kyc/kyc-actions";
import { useAppDispatch, useTypedSelector } from "redux/store";
import { getCurrentCompanySelector } from "redux/companies";
import {
  DynamicFormAPI,
  KYCQuestionType,
  KYCSaveStatusEnum,
  KYCSaveStatusMessage,
} from "types/enums";
import { Answer, KYCForm } from "types/models";
import { KYCDataDto, KycQuestionAnswer } from "models/KYCDtos";
import { initialKycDto } from "models/initials";
import { mapCheckboxAnswers } from "utils/kycUtils";
import { hasOwnProperty } from "utils/typeUtils";
import { getCompanyByNumber } from "redux/companies/companies-actions";

type OwnProps = {};

export const KYCFormPage: React.FC<OwnProps> = (_props: OwnProps) => {
  const dispatch = useAppDispatch();
  const kycForm = useTypedSelector(kycFormSelector);
  const currentCompany = useTypedSelector(getCurrentCompanySelector);
  const initialKycData = useTypedSelector(initialKycDataSelector);

  useEffect(() => {
    const fetchDataForKyc = async () => {
      await Promise.all([
        dispatch(getKYCForm()),
        dispatch(getAllCountries()),
        dispatch(
          setInitialKycData({
            mainAddress: currentCompany?.address,
            zipCode: currentCompany?.zipCode,
            city: currentCompany?.city,
            registeredOfficeCity: currentCompany?.registeredOfficeCity,
          })
        ),
      ]);
    };

    fetchDataForKyc();
  }, [dispatch, currentCompany]);

  const handleKycFormSubmit = (
    data: KYCDataDto,
    kycForm: KYCForm | undefined
  ) => {
    const allQuestions: {
      id: string;
      name: string;
      answers?: Answer[];
      hasPredefinedAnswers: boolean;
      defaultAnswerExternalId?: string;
      api?: DynamicFormAPI;
      questionType?: KYCQuestionType;
    }[] = [];

    if (kycForm) {
      for (const grp of kycForm.groups) {
        for (const question of grp.questions) {
          allQuestions.push({
            id: question.externalId.toString(),
            name: question.name,
            answers: question.answers,
            hasPredefinedAnswers:
              question.answers && question.answers.length !== 0,
            defaultAnswerExternalId:
              question.defaultAnswerExternalId ?? undefined,
            api: question.api,
            questionType: question.questionType,
          });
          if (question.additionalQuestions) {
            for (const add of question.additionalQuestions) {
              allQuestions.push({
                id: add.externalId.toString(),
                name: add.name,
                answers: add.answers,
                hasPredefinedAnswers: add.answers && add.answers.length !== 0,
                defaultAnswerExternalId:
                  add.defaultAnswerExternalId ?? undefined,
              });
            }
          }
        }
      }
    }

    const kycDto = initialKycDto;
    const qa = [] as KycQuestionAnswer[];

    allQuestions.forEach((question) => {
      const answer = mapCheckboxAnswers(question, data);

      const defaultAnswerExternalId = question.defaultAnswerExternalId;

      if (answer !== undefined || defaultAnswerExternalId !== undefined) {
        // Add to Q&A list if destination API is IP
        if (question.api === DynamicFormAPI.IP) {
          if (hasOwnProperty(kycDto, question.name)) {
            kycDto[question.name] = answer;
          }
        } else {
          const answerArr = defaultAnswerExternalId
            ? [defaultAnswerExternalId]
            : typeof answer === "string"
            ? [answer]
            : answer;

          qa.push({
            questionId: question.id,
            answer: answerArr as string[],
          });
        }
      }
    });

    kycDto.questionsAndAnswers = qa;

    dispatch(
      saveKYCData({
        data: kycDto,
        businessNumber: currentCompany?.businessNumber ?? "",
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(
          setKycSaveStatus({
            visible: true,
            status: KYCSaveStatusEnum.Success,
            message: KYCSaveStatusMessage.Success,
          })
        );
        dispatch(getCompanyByNumber(currentCompany?.businessNumber ?? ""));
      })
      .catch(() => {
        dispatch(
          setKycSaveStatus({
            visible: true,
            status: KYCSaveStatusEnum.Failed,
            message: KYCSaveStatusMessage.Failed,
          })
        );
      });
  };

   if (!initialKycData) return <div>Loading...</div>

  return (
    <Grid paddingY={2}>
      <DynamicKYCForm
        form="kycform"
        onSubmit={async (values) => handleKycFormSubmit(values, kycForm)}
        initialValues={initialKycData}
      />
    </Grid>
  );
};

export default KYCFormPage;
