import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import TopBar from "components/Popup/TopBar";
import { PopupActionProps } from "types/types";
import { PopupType } from "types/enums";
import AddCompanyContent from "./AddCompanyContent";

const useStyles = makeStyles()({
  container: {
    flexGrow: 1,
    position: "relative",
    width: "auto",
  },
  contentContainer: {
    width: "488px",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

interface OwnProps {
  type: PopupType;
  onInputChangeValue?: (value: string, fieldName: string) => void;
}

type Props = OwnProps & PopupActionProps;

export default function PopupBody(props: Props): JSX.Element {
  const { type, onClose, onSubmit, onInputChangeValue } = props;
  const { classes } = useStyles();

  switch (type) {
    case PopupType.OneTimeCode:
      return (
        <Grid container className={classes.container}>
          <Grid item className={classes.contentContainer}>
            <TopBar onClose={onClose} title={"Lisää uusi yritys"} />
            <AddCompanyContent
              onClose={onClose}
              onSubmit={onSubmit}
              onInputChangeValue={onInputChangeValue}
            />
          </Grid>
        </Grid>
      );
    default:
      return <></>;
  }
}
