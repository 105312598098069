import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export type UiState = {
  addNewCompanyPopupOpen: boolean;
  popupErrorMessage: PopupErrorMessage;
};

interface PopupErrorMessage {
  visible: boolean;
  message?: string;
}

const initialState: UiState = {
  addNewCompanyPopupOpen: false,
  popupErrorMessage: {
    visible: false,
    message: undefined,
  },
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setAddNewCompanyPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.addNewCompanyPopupOpen = action.payload;
    },
    setPopupErrorMessage: (state, action: PayloadAction<PopupErrorMessage>) => {
      state.popupErrorMessage = action.payload;
    },
  },
});

export const addNewCompanyPopupOpen = createSelector(
  (state: RootState): boolean => state.ui.addNewCompanyPopupOpen,
  (it) => it
);

export const popupErrorMessageSelector = createSelector(
  (state: RootState): PopupErrorMessage => state.ui.popupErrorMessage,
  (it) => it
);

export const { setAddNewCompanyPopupOpen, setPopupErrorMessage } =
  uiSlice.actions;
