import React from "react";
import { RadioGroup } from "@mui/material";
import { WrappedFieldProps } from "redux-form";
import { Common } from "./Common";

const renderRadio = (props: Common & WrappedFieldProps) => {
  const { input, meta, id, ...nonprops } = props;
  const inputValue =
    typeof input.value !== "string" ? input.value.toString() : input.value;
  return (
    <div>
      <RadioGroup
        aria-label="requestedReferenceRate"
        {...input}
        {...nonprops}
        id={id}
        value={inputValue}
        onChange={(event: React.FormEvent<EventTarget>, value: string) =>
          input.onChange(value)
        }
      />
      {props.required && meta.touched && (
        <label htmlFor={id}>
          <span>
            <span style={{ color: "#C85200" }} tabIndex={0}>
              {meta.touched && meta.error}
            </span>
          </span>
        </label>
      )}
    </div>
  );
};

export default renderRadio;
