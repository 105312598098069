import React from "react";

interface CloseButtonIconProps {
  onClick?: (
    event: React.MouseEvent<SVGSVGElement | HTMLButtonElement, MouseEvent>
  ) => void;
  enablePointer?: boolean;
}

export const CloseButtonIcon = (props: CloseButtonIconProps): JSX.Element => {
  const { enablePointer = true } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      cursor={enablePointer ? "pointer" : undefined}
    >
      <circle cx="10" cy="10" r="9.5" stroke="#92C9F0" />
      <path d="M10 10L6 6M10 10L14 6M10 10L14 14M10 10L6 14" stroke="#92C9F0" />
    </svg>
  );
};
