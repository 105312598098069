import {
    PayloadAction,
    SerializedError,
    createSelector,
    createSlice,
  } from "@reduxjs/toolkit";
  import { RootState } from "./store";
import { AuthenticationInfo } from "types/AuthenticationInfo";
import { loadAuthenticationInfos } from "./authenticationInfos/authenticationInfo-actions";
  
  export interface AuthenticationInfoState {
    loading: boolean;
    error: SerializedError | null;
    data: AuthenticationInfo[]
  }
  
  const initialState: AuthenticationInfoState = {
    loading: false,
    error: null,
    data: [],
  };

  export const authenticationInfoSlice = createSlice({
    name: "authenticationinfo",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(loadAuthenticationInfos.pending, (state, _action) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(loadAuthenticationInfos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      builder.addCase(
        loadAuthenticationInfos.fulfilled,
        (state, action: PayloadAction<AuthenticationInfo[] | undefined>) => {
          if (action.payload !== undefined) {
            state.data = action.payload;
            state.loading = false;
          }
        }
      );
    },
  });
  
  export const getAuthenticationInfoSelector = createSelector(
    (state: RootState): AuthenticationInfo[] => state.authenticationInfo.data,
    (it) => it
  );
  
  