import React from "react";

const CustomCheckboxIconChecked = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="26" height="26" rx="2" fill="#0069B4" />
      <path d="M5 12L11 18L22 7" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export default CustomCheckboxIconChecked;
