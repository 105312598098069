import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import queryString from "query-string";
import { postToken } from "redux/oidc/oidc-actions";
import { useAppDispatch } from "redux/store";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { PrivateRoutesEnum, RoutesEnum } from "App";
import { AuthInfo } from "types/models";

const useStyles = makeStyles()((theme) => ({
  container: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px",
  },
  redirectText: {
    fontFamily: theme.hypoFonts.gotham.book,
    marginBottom: 10,
  },
}));

const Redirect = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    const makeRequest = async () => {
      const query = queryString.parse(location.search);
      if (query.error) {
        console.log(query.error);
      }

      if (query.code && query.state) {
        dispatch(
          postToken({
            code: query.code as string,
            state: query.state as string,
          })
        )
          .then((res) => {
            var tokenRes = res.payload as AuthInfo;

            if (tokenRes.accessToken) {
              navigate(PrivateRoutesEnum.ChooseCompany);
            }
          })
          .catch(() => navigate(RoutesEnum.Error));
      }
    };
    makeRequest();
  }, [location, dispatch, navigate]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classes.container}
    >
      <Typography variant="h5" className={classes.redirectText}>
        Uudelleenohjataan
      </Typography>
      <CircularProgress />
    </Grid>
  );
};

export default React.memo(Redirect);
