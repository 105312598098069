import React from "react";
import { makeStyles } from "tss-react/mui";
import { Grid } from "@mui/material";
import { Question } from "types/models";
import { isRequired } from "utils/kycUtils";
import CustomExtendedField from "./CustomExtendedField";
import renderCustomSelectField from "./RenderCustomSelectField";
import RenderQuestionParameter from "./QuestionParameters/RenderQuestionParameter";

interface Props {
  options: { label: string; value: string }[];
  question?: Question;
  key?: string;
  innerElement?: React.ReactNode;
  formState?: any;
  justifyFieldTo?: string;
  renderAlternativeQuestionTitle?: boolean;
}

const useStyles = makeStyles<{
  justifyFieldTo?: string;
}>()((_theme, { justifyFieldTo }) => ({
  selectContainer: {
    justifyContent: justifyFieldTo,
    marginTop: 10,
    padding: 0,
  },
}));

const RenderSelect = (props: Props) => {
  const {
    options,
    question,
    renderAlternativeQuestionTitle,
    formState,
    innerElement,
    justifyFieldTo,
    key,
  } = props;
  const { classes } = useStyles({
    justifyFieldTo: justifyFieldTo,
  });

  return (
    <Grid
      container
      className={classes.selectContainer}
      flexDirection={innerElement ? "row-reverse" : "row"}
      key={key}
    >
      {innerElement}
      <Grid container item lg={8} md={12}>
        <Grid item xs={12} style={{ padding: 0 }}>
          <RenderQuestionParameter header={question?.parameters.header} />
          <RenderQuestionParameter subHeader={question?.parameters.subHeader} />
          <RenderQuestionParameter
            infoText={question?.parameters.infoText}
            additionalStyles={{ paddingBottom: "8px" }}
          />
          <RenderQuestionParameter
            secondInfoText={question?.parameters.secondInfoText}
            additionalStyles={{ paddingBottom: "8px" }}
          />
        </Grid>
        <CustomExtendedField
          id={question?.name}
          name={question?.name ?? ""}
          component={renderCustomSelectField}
          label={
            renderAlternativeQuestionTitle
              ? question?.parameters.alternativeQuestionTitle
              : question?.titleFI
          }
          options={options}
          renderEmptyCellItem={true}
          adornment={question?.parameters.adornment}
          required={isRequired(question, formState)}
          aria-label={
            renderAlternativeQuestionTitle
              ? question?.parameters.alternativeQuestionTitle
              : question?.titleFI
          }
        />
      </Grid>
    </Grid>
  );
};

export default RenderSelect;
