import React from "react";

const CustomRadioIconChecked = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5 12.8283C23.5 19.3113 18.3426 24.551 12 24.551C5.65735 24.551 0.5 19.3113 0.5 12.8283C0.5 6.34532 5.65735 1.10559 12 1.10559C18.3426 1.10559 23.5 6.34532 23.5 12.8283Z"
        stroke="#0069B4"
      />
      <ellipse cx="12" cy="12.8283" rx="10" ry="10.1856" fill="#646363" />
    </svg>
  );
};

export default CustomRadioIconChecked;
