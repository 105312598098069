import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Link, Typography } from "@mui/material";
import NavigationLinks from "./Navigation/NavigationLinks";
import { RouteDescription } from "types/models";
import ComboBox, { defaultItemToString } from "./ComboBox";
import { makeStyles } from "tss-react/mui";
import { useAppDispatch, useTypedSelector } from "redux/store";
import {
  getCompaniesSelector,
  getCurrentCompanySelector,
  selectCurrentLoading,
} from "redux/companies";
import {
  addCompany,
  fetchCompanies,
  getCompanyByNumber,
} from "redux/companies/companies-actions";
import { setAddNewCompanyPopupOpen, setPopupErrorMessage } from "redux/ui";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import clsx from "clsx";
import { Popup } from "./Popup";
import { PopupType } from "types/enums";
import { addNewCompanyPopupOpen } from "redux/ui";
import { resetKycSaveStatus } from "redux/kyc";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  formatBusinessNumber,
  parseBusinessNumberFromItem,
} from "utils/stringUtils";
import { PrivateRoutesEnum } from "App";
import { getAuthInfoSelector } from "redux/oidc";

const useStyles = makeStyles()((theme) => ({
  companyLoadingContainer: {
    marginTop: 5,
    flexWrap: "nowrap",
  },
  dropdownContainer: {
    backgroundColor: "#e36b22",
    padding: "10px 10px",
  },
  dropdownContainerBlue: {
    backgroundColor: "#9ac8ef",
    padding: "10px 10px",
  },
  loadingCompanyIcon: {
    color: "#ffffff",
    float: "right",
    marginRight: 8,
    marginTop: 5,
  },
  notFoundErrorIcon: {
    color: "#ffffff",
    fontSize: "1rem",
  },
  notFoundErrorText: {
    color: "#ffffff",
    fontFamily: theme.hypoFonts.gotham.book,
    fontSize: 11,
    marginLeft: 5,
  },
  addNewCompanyLink: {
    fontFamily: theme.hypoFonts.gotham.book,
    fontSize: 12,
    color: "#FFFFFF",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type SideNavProps = {
  routes?: RouteDescription[];
};

export const SideNav: React.FC<SideNavProps> = (props: SideNavProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [oneTimeCode, setOneTimeCode] = useState<string | null>(null);
  const [companyNotFound, setCompanyNotFound] = useState<boolean>(false);
  const [newCompanyAdded, setNewCompanyAdded] = useState<boolean>(false);

  const companies = useTypedSelector(getCompaniesSelector);
  const popupOpen = useTypedSelector(addNewCompanyPopupOpen);
  const currentCompany = useTypedSelector(getCurrentCompanySelector);
  const currentCompanyLoading = useTypedSelector(selectCurrentLoading);
  const authInfo = useTypedSelector(getAuthInfoSelector);

  useEffect(() => {
    dispatch(fetchCompanies(authInfo?.id ?? ""));
    if (!currentCompany) {
      navigate(PrivateRoutesEnum.ChooseCompany);
    }
  }, [dispatch, navigate, authInfo?.id]);

  useEffect(() => {
    if (currentCompany) {
      if (location.pathname !== PrivateRoutesEnum.FillForm) {
        navigate(PrivateRoutesEnum.FillForm);
      }
    }
  }, [dispatch, currentCompany, navigate, location.pathname]);

  const comboboxData = [
    null,
    ...companies.map((c) =>
      c.companyName
        ? `${c.companyName} (${formatBusinessNumber(c.businessId)})`
        : formatBusinessNumber(c.businessId)
    ),
  ];

  const handleComboboxSelect = (item: string | null) => {
    setNewCompanyAdded(false);
    setCompanyNotFound(false);

    if (item) {
      const businessNumber = parseBusinessNumberFromItem(item);
      dispatch(getCompanyByNumber(formatBusinessNumber(businessNumber)))
        .then(unwrapResult)
        .catch(() => setCompanyNotFound(true));

      dispatch(resetKycSaveStatus());
      dispatch(setAddNewCompanyPopupOpen(false));
    }
  };

  const openAddNewCompanyPopup = () => {
    setNewCompanyAdded(false);
    dispatch(setAddNewCompanyPopupOpen(true));
  };

  const addCompanyWithOneTimeCode = () => {
    dispatch(
      setPopupErrorMessage({
        visible: false,
      })
    );

    dispatch(
      addCompany({ UserId: authInfo?.id ?? "", Code: oneTimeCode as string })
    )
      .then(unwrapResult)
      .then((res) => {
        dispatch(setAddNewCompanyPopupOpen(false));
        setNewCompanyAdded(true);
      })
      .catch(() => {
        dispatch(
          setPopupErrorMessage({
            visible: true,
            message:
              "Koodi on jo käytetty, vanhentunut tai muutoin virheellinen. Tarkista koodi.",
          })
        );
      });
  };

  const handlePopupClose = () => {
    dispatch(
      setPopupErrorMessage({
        visible: false,
      })
    );
    dispatch(setAddNewCompanyPopupOpen(false));
    setOneTimeCode(null);
  };

  return (
    <>
      <Grid container>
        <Grid
          container
          className={clsx({
            [classes.dropdownContainer]: true,
            [classes.dropdownContainerBlue]: currentCompany,
          })}
        >
          <Grid item xl={5} sm={0}></Grid>
          <Grid item xl={7} sm={12}>
            <Grid>
              {currentCompanyLoading && (
                <CircularProgress
                  className={classes.loadingCompanyIcon}
                  size={15}
                />
              )}
              <ComboBox
                onSelectedItemChange={(item) => handleComboboxSelect(item)}
                items={comboboxData}
                initialSelectedItem={comboboxData[0]}
                itemToString={defaultItemToString}
                label="Valitse yritys"
                placeholder="Valitse yritys"
                isSideNav
              />
            </Grid>
            <Grid container className={classes.companyLoadingContainer}>
              {newCompanyAdded && (
                <Grid container>
                  <CheckIcon className={classes.notFoundErrorIcon} />
                  <Typography className={classes.notFoundErrorText}>
                    Yritys lisätty
                  </Typography>
                </Grid>
              )}
              {companyNotFound && (
                <Grid container>
                  <ErrorIcon className={classes.notFoundErrorIcon} />
                  <Typography className={classes.notFoundErrorText}>
                    Yritystä ei löytynyt
                  </Typography>
                </Grid>
              )}
              <Grid container justifyContent="flex-end">
                <Link onClick={openAddNewCompanyPopup}>
                  <Typography className={classes.addNewCompanyLink}>
                    Lisää uusi yritys
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {location.pathname !== PrivateRoutesEnum.ChooseCompany && (
          <Grid container>
            <Grid item xl={5} sm={0}></Grid>
            <Grid item xl={7} sm={12}>
              <NavigationLinks routes={props.routes} />
            </Grid>
          </Grid>
        )}
      </Grid>
      {popupOpen && (
        <Popup
          type={PopupType.OneTimeCode}
          open={popupOpen}
          onInputChangeValue={(value: string, fieldName: string) =>
            setOneTimeCode(value)
          }
          onSubmit={() => addCompanyWithOneTimeCode()}
          onClose={handlePopupClose}
        />
      )}
    </>
  );
};

export default SideNav;
