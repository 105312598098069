import * as request from "utils/request";
import { AuthenticationInfo } from "types/AuthenticationInfo";
import { createAsyncThunk } from "@reduxjs/toolkit";

const apiUrl = process.env.REACT_APP_API_URL;


export const loadAuthenticationInfos = createAsyncThunk(
  "authenticationinfo/loadInfo",
  async () => {
    try {
      const response = await request.getResponseJson<AuthenticationInfo[]>(
        `${apiUrl}/api/authenticationInfo/GetAuthInfo`
      );

      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

