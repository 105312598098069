import React from "react";
import { FormControl, Select } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { WrappedFieldProps } from "redux-form";
import { makeStyles } from "tss-react/mui";
import CustomLensIcon from "components/icons/CustomLensIcon";

const useStyles = makeStyles()((theme) => ({
  selectAdornment: {
    position: "absolute",
    padding: 0,
    right: "32px",
    fontFamily: theme.hypoFonts.gotham.bold,
  },
}));

interface OwnProps {
  id?: string;
  label?: string;
  name?: string;
  className?: string;
  options?: any;
  useAriaLabel?: boolean;
  renderEmptyCellItem?: boolean;
  required?: boolean;
  adornment?: string;
  onChangeCapture?: () => {};
}

type Props = OwnProps & WrappedFieldProps;
/**
 * @component This component replaces RenderSelectField.tsx and is intented fulfill WCAG 2.1 requirements
 * @usage Works well with Field-component from redux-form.
 */
const RenderCustomSelectField = ({
  id,
  input,
  label,
  meta,
  options,
  name,
  className,
  useAriaLabel,
  renderEmptyCellItem = false,
  required,
  adornment,
  ...custom
}: Props) => {
  const { classes } = useStyles();
  // const errorClass = meta.touched && meta.error ? " error" : "";
  let optionCells: JSX.Element[] = [];
  renderEmptyCellItem &&
    optionCells.push(
      <option key={""} value={""}>
        {""}
      </option>
    );
  options &&
    options.forEach((element: any) => {
      const dis = element.header ? true : false;

      const cell = (
        <option
          disabled={dis}
          key={element.value}
          value={element.value}
          style={{ textIndent: 5 }}
        >
          {element.label}
        </option>
      );

      optionCells.push(cell);
    });
  const inputProps = useAriaLabel
    ? {
        name: name,
        id: id,
        "aria-label": id,
      }
    : { name: name, id: id };
  return (
    <FormControl
      error={meta.touched && meta.error !== undefined}
      style={{ width: "100%" }}
    >
      <Select
        native
        {...input}
        {...custom}
        onChangeCapture={custom.onChangeCapture}
        className={className}
        inputProps={inputProps}
        endAdornment={
          adornment && (
            <InputAdornment position="end" className={classes.selectAdornment}>
              <span tabIndex={0} style={{ fontWeight: 600 }}>
                {adornment}
              </span>
            </InputAdornment>
          )
        }
      >
        {optionCells}
      </Select>
      <label htmlFor={id}>
        {label}
        {required && (
          <span>
            <CustomLensIcon />
            <br />
            <span style={{ color: "red" }} tabIndex={0}>
              {meta.touched && meta.error}
            </span>
          </span>
        )}
      </label>
    </FormControl>
  );
};

export default RenderCustomSelectField;
