import { KycDto } from "./KYCDtos";

export const initialKycDto: KycDto = {
  questionsAndAnswers: [],
  countriesWithTaxation: undefined,
  listOfOwnerCompanies: undefined,
  listOfBeneficiaries: undefined,
  businessName: undefined,
  organizationNumber: undefined,
  LeiCode: undefined,
  mainAddress: undefined,
  zipCode: undefined,
  city: undefined,
  registeredOfficeCity: undefined,
  legalForm: undefined,
  phoneNumber: undefined,
  email: undefined,
  startingDate: undefined,
  registerationDate: undefined,
  contactName: undefined,
  contactPhonenumber: undefined,
  contactEmail: undefined,
  numberOfEmployees: undefined,
  TolCode: undefined,
  businessDescription: undefined,
  accountingPeriodStartDate: undefined,
  accountingPeriodEndDate: undefined,
  accountingPeriodLength: undefined,
  accountingPeriod: undefined,
  turnover: undefined,
  balance: undefined,
  bankCustomerAbroad: false,
  fatcaClassification: undefined,
  giinId: undefined,
};
