import React from "react";
import { Grid, Button } from "@mui/material";
import { Question } from "types/models";
import CustomExtendedField from "./CustomExtendedField";
import renderCustomSelectField from "./RenderCustomSelectField";

interface Props {
  fieldArrayProps: any;
  question: Question;
  listOptions: { label: string; value: string }[];
  required: boolean;
}

const RenderSelectionList = (props: Props) => {
  const { fieldArrayProps, listOptions, question, required } = props;

  if (fieldArrayProps.fields.length === 0) {
    fieldArrayProps.fields.push();
  }
  const options = listOptions;

  return (
    <ul aria-live="polite" style={{ listStyle: "none", paddingLeft: 0 }}>
      {fieldArrayProps.fields &&
        fieldArrayProps.fields.map((fieldArrayProp: any, key: any) => {
          if (key !== 0) {
            return (
              <li key={key}>
                <Grid container paddingY={0.5}>
                  <Grid item xs={8}>
                    <CustomExtendedField
                      id={`${question.name}_${key}`}
                      aria-label={question.titleFI}
                      name={`${fieldArrayProp}`}
                      component={renderCustomSelectField}
                      label={question.titleFI}
                      options={options}
                      renderEmptyCellItem={true}
                      required={required}
                    />
                  </Grid>
                  <Grid container item xs={4} justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => fieldArrayProps.fields.remove(key)}
                      style={{
                        minHeight: 54,
                        minWidth: 0,
                        width: "95%",
                      }}
                    >
                      {question.parameters.removeButtonTitle}
                    </Button>
                  </Grid>
                </Grid>
              </li>
            );
          }
          return (
            <li key={key}>
              <Grid container>
                <Grid item xs={8} md={8}>
                  <CustomExtendedField
                    id={`${question.name}_${key}`}
                    name={`${fieldArrayProp}`}
                    component={renderCustomSelectField}
                    label={question.titleFI}
                    options={options}
                    renderEmptyCellItem={true}
                    required={required}
                  />
                </Grid>
              </Grid>
            </li>
          );
        })}
      <li>
        <Grid container>
          <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
            <Button
              id={`${question.name}Button`}
              color="primary"
              fullWidth={true}
              variant="text"
              onClick={() => {
                fieldArrayProps.fields.push();
              }}
              style={{ minHeight: "54px", minWidth: 0 }}
              disabled={fieldArrayProps.fields.length > 3}
            >
              {question.parameters.addButtonTitle}
            </Button>
          </Grid>
        </Grid>
      </li>
    </ul>
  );
};

export default RenderSelectionList;
