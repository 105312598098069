import React from "react";
import { Grid, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ isError?: boolean }>()((theme, { isError }) => ({
  icon: {
    color: isError ? "#ed4444" : "#0fb10f",
    fontSize: "1.4rem",
  },
  statusTextContainer: {
    margin: "4px 0 20px 0",
  },
  statusText: {
    color: isError ? "#ed4444" : "#0fb10f",
    paddingLeft: 10,
    fontFamily: theme.hypoFonts.gotham.book,
  },
}));

interface OwnProps {
  statusText: string;
  isError?: boolean;
}

const StatusText = (props: OwnProps) => {
  const { isError, statusText } = props;
  const { classes } = useStyles({ isError: isError });

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.statusTextContainer}
    >
      {isError ? (
        <ErrorIcon className={classes.icon} />
      ) : (
        <CheckCircleIcon className={classes.icon} />
      )}
      <Typography className={classes.statusText}>{statusText}</Typography>
    </Grid>
  );
};

export default StatusText;
