import React from "react";
import { Container, Theme, Grid, styled } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import { RouteDescription } from "types/models";
import { SideNav } from "./SideNav";
import { TopBar } from "./TopBar";
import { useLocation } from "react-router-dom";
import { PrivateRoutesEnum } from "App";

type LayoutProps = {
  basename: string;
  children?: React.ReactNode;
  routes?: RouteDescription[];
};

const LayoutDiv = styled("div")({
  // LayoutDiv specific styles here
});

const useStyles = makeStyles()((_theme: Theme) => {
  return {
    content: {
      paddingTop: "46px",
    },
    mainContainer: {
      margin: 0,
      maxWidth: "unset",
    },
  };
});

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { classes } = useStyles();
  const location = useLocation();

  return (
    <LayoutDiv>
      <TopBar routes={props.routes} basename={props.basename} />
      {Object.values(PrivateRoutesEnum).includes(location.pathname as PrivateRoutesEnum) ? (
        <Grid container className={clsx(classes.content)}>
          <Grid item xs={3}>
            <SideNav routes={props.routes} />
          </Grid>
          <Grid item xs>
            <Container component="main" className={classes.mainContainer}>
              {props.children ?? ""}
            </Container>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      ) : (
        <Grid item xs>
          <Container component="main" className={classes.content}>
            {props.children ?? ""}
          </Container>
        </Grid>
      )}
    </LayoutDiv>
  );
};

export default Layout;
