interface Props {
  small: boolean;
}

const Avatar = (props: Props): JSX.Element => {
  const { small } = props;
  return (
    <svg
      width={small ? "20" : "26"}
      height={small ? "20" : "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="13" fill="white" />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <circle cx="13" cy="13" r="12.1875" fill="#004170" />
      </mask>
      <g mask="url(#mask0)">
        <circle cx="13" cy="26.8125" r="13" fill="#004170" />
        <circle cx="13" cy="8.125" r="4.875" fill="#004170" />
      </g>
    </svg>
  );
};

export default Avatar;
