import React from "react";
import { Grid } from "@mui/material";
import { FieldArray } from "redux-form";
import { Question } from "types/models";
import { isRequired } from "utils/kycUtils";
import RenderQuestionParameter from "./QuestionParameters/RenderQuestionParameter";
import RenderSelectionList from "./RenderSelectionList";

interface Props {
  question: Question;
  options: { label: string; value: string }[];
  key?: string;
  formState?: any;
}

const RenderSelectionListQuestion = (props: Props) => {
  const { options, question, formState, key } = props;

  return (
    <Grid
      container
      style={{
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 0,
        marginRight: 0,
        padding: 0,
      }}
      key={key}
    >
      <RenderQuestionParameter header={question.parameters.header} />
      <RenderQuestionParameter
        infoText={question.parameters.infoText}
        additionalStyles={{ paddingBottom: "8px" }}
      />
      <Grid item xs={12}>
        <FieldArray
          name={question.name}
          component={(fieldArrayProps: any) => (
            <RenderSelectionList
              fieldArrayProps={fieldArrayProps}
              question={question}
              listOptions={options}
              required={isRequired(question, formState)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default RenderSelectionListQuestion;
