import { Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

type OwnProps = {};

const useStyles = makeStyles()((theme) => ({
  header: {
    marginTop: 20,
    fontSize: 20,
    color: theme.hypoColors.darkGrey,
    fontFamily: theme.hypoFonts.gotham.bold,
  },
}));

export const ChooseCompanyPage: React.FC<OwnProps> = (_props: OwnProps) => {
  const { classes } = useStyles();
  return (
    <Typography component={"h1"} className={classes.header}>
      Valitse ensin vasemmasta laidasta "Lisää uusi yritys", jolloin saat
      kertakäyttötunnuksella lisättyä uuden yrityksen. Tämän jälkeen voit valita
      yrityksen ja täyttää sen tuntemislomakkeen.
    </Typography>
  );
};

export default ChooseCompanyPage;
