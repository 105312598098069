import { createTheme } from "@mui/material/styles";
declare module "@mui/material/styles" {
  interface CustomTheme {
    hypoColors: {
      lighterGrey: string;
      darkGrey: string;
      lightBlue: string;
      blue: string;
    };
    hypoFonts: {
      gotham: {
        book: string;
        bold: string;
      };
      arnoPro: {
        bold: string;
        regular: string;
      };
    };
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

// Customize theme here
export const muiTheme = createTheme({
  hypoColors: {
    blue: "#92c9f0",
    darkGrey: "#646363",
    lightBlue: "#0069b4",
    lighterGrey: "#c4c4c4",
  },
  hypoFonts: {
    gotham: {
      bold: "Gotham Bold",
      book: "Gotham Book",
    },
    arnoPro: {
      bold: "ArnoPro Bold",
      regular: "ArnoPro Regular",
    },
  },
  palette: {
    primary: {
      light: "#92C9F0",
      main: "#004170",
      dark: "#004170",
      contrastText: "#FFFFFF",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&::before": {
            borderBottom: "1px solid #92C9F0",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "10px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "none",
          borderBottom: "1px solid #92C9F0",
          padding: 0,
        },
        root: {
          borderRadius: "0px",
          fontFamily: "Arial",
          fontSize: "14px",
          fontWeight: "normal",
          fontStyle: "normal",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 0,
          border: "none",
          backgroundColor: "#004170",
          color: "#FFFFFF",
          "&:disabled": {
            backgroundColor: "#E5E5E5",
          },
          "&:hover": {
            backgroundColor: "#92C9F0",
            color: "#000000",
          },
          fontFamily: "Gotham Book",
        },
        outlined: {
          borderRadius: 0,
          color: "#000000",
          background: "#FFFFFF",
          border: "1px solid #92C9F0",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        extended: {
          borderRadius: 0,
          backgroundColor: "#004170",
          textTransform: "none",
          border: "none",
          color: "#FFFFFF",
          "&:disabled": {
            backgroundColor: "#E5E5E5",
          },
          "&:hover": {
            backgroundColor: "#92C9F0",
            color: "#000000",
          },
          fontFamily: "Raleway",
        },
      },
    },
  },
});
