import { RoutesEnum } from "App";
import { Navigate, Outlet } from "react-router-dom";
import { useTypedSelector } from "redux/store";
import { getAuthInfoSelector } from "redux/oidc";

export const PrivateRoutes = () => {
  const authInfo = useTypedSelector(getAuthInfoSelector);

  if (!authInfo?.accessToken) {
    return <Navigate to={RoutesEnum.Root} />;
  }

  return <Outlet />;
};
