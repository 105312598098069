import { createAsyncThunk } from "@reduxjs/toolkit";
import * as request from "utils/request";
import { KYCForm } from "types/models";
import { KycDto } from "models/KYCDtos";

const apiUrl = process.env.REACT_APP_API_URL;

interface ISaveThunkArgs {
  data: KycDto;
  businessNumber: string;
}

export const getKYCForm = createAsyncThunk("kyc/get-kyc-form", async () => {
  try {
    const response = await request.getResponseJson<KYCForm>(
      `${apiUrl}/api/dynamicform/1`
    );

    return response;
  } catch (err) {
    throw err;
  }
});

export const saveKYCData = createAsyncThunk(
  "kyc/save-kyc-data",
  async ({ data, businessNumber }: ISaveThunkArgs) => {
    try {
      const response = await request.put(
        `${apiUrl}/api/kyc/save?businessNumber=${businessNumber}`,
        data
      );

      return response;
    } catch (err) {
      throw err;
    }
  }
);
