import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CloseButtonIcon } from "components/icons";
import { PopupActionProps } from "types/types";

const useStyles = makeStyles()((theme) => ({
  topBar: {
    display: "flex",
    alignItems: "center",
    height: "34px",
    background:
      "linear-gradient(3.99deg, #004170 0%, #004170 64.64%, #0069B4 100%)",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  topBarLeft: {
    justifyContent: "flex-start",
  },
  topBarRight: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-end",
    paddingRight: "10px",
  },
  headerText: {
    color: "#FFFFFF",
    fontFamily: theme.hypoFonts.gotham.book,
    fontWeight: 600,
    fontSize: "18px",
    paddingLeft: "10px",
  },
}));

interface OwnProps {
  title: string;
}

type Props = OwnProps & PopupActionProps;

export default function TopBar(props: Props): JSX.Element {
  const { classes } = useStyles();
  const { onClose, title } = props;
  return (
    <Grid container>
      <Grid item xs={12} className={classes.topBar}>
        <Grid container>
          <Grid item xs className={classes.topBarLeft}>
            <Typography className={classes.headerText}>{title}</Typography>
          </Grid>
          <Grid item xs={1} className={classes.topBarRight}>
            <CloseButtonIcon onClick={onClose} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
