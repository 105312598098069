export const formatBusinessNumber = (businessNumber: string) => {
  if (businessNumber.includes("-")) {
    return businessNumber.replace("-", "");
  }

  return `${businessNumber.substr(
    0,
    businessNumber.length - 1
  )}-${businessNumber.substr(businessNumber.length - 1)}`;
};

export const parseBusinessNumberFromItem = (item: string) => {
  const lastPart = item.split("(").pop() ?? "";
  return lastPart.split(")")[0];
};
