import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { getBaseUrl } from "utils/routeUtils";
import { ThemeProvider } from "@mui/material/styles";
import { muiTheme } from "theme";
import { store, persistor } from "redux/store";
import { logger } from "services/logger";
import { PersistGate } from "redux-persist/integration/react";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);
const baseUrl = getBaseUrl();

const render = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const App = require("./App").App;

  root.render(
    <React.StrictMode>
      <React.Suspense fallback="loading">
        <Provider store={store}>
          <ThemeProvider theme={muiTheme}>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter basename={baseUrl}>
                <App />
              </BrowserRouter>
            </PersistGate>
          </ThemeProvider>
        </Provider>
      </React.Suspense>
    </React.StrictMode>
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  // Hot reloadable app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./App"], () => {
    logger.log("Hot reload");
    ReactDOM.unmountComponentAtNode(rootElement);
    render();
  });
}
