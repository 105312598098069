// import { RootAction, RootDispatch } from "redux/rootReducer";
// import { removeState } from "redux/oidc/oidcFunctions";
// import { store } from "../redux/store";

import { store } from "redux/store";

interface Headers {
  [key: string]: string;
}

interface XMLHttpRequestWithHeaders extends XMLHttpRequest {
  headers: Headers;
}

interface SuccessfulProgressEvent<T extends EventTarget>
  extends ProgressEvent<T> {
  target: T;
}

const globalHeaders: Map<string, string> = new Map();
const activeRequests: Map<string, XMLHttpRequest> = new Map();
/**
 * Basic Promise wrapper for XMLHttpRequest
 * @param  {string} method       The method of the request, e.g. 'GET' or 'POST'
 * @param  {string} url          The url
 * @param  {any} [data=null]     The data to send with the request
 * @param  {object} [headers={}] The request header parameters
 * @param  {string} type         Type of request for cancelling if another of same type is already in progress
 * @return {Promise}             Returns a promise that resolves or rejects based on the request status
 */
async function promiseRequest(
  method: string,
  url: string,
  data?: any,
  headers: Headers = {},
  type?: string,
  blob: boolean = false
): Promise<SuccessfulProgressEvent<XMLHttpRequestWithHeaders>> {
  return new Promise<SuccessfulProgressEvent<XMLHttpRequestWithHeaders>>(
    (resolve, reject) => {
      if (type) {
        const activeRequest = activeRequests.get(type);
        if (activeRequest) {
          activeRequest.abort();
          activeRequests.delete(type);
        }
      }

      const request = new XMLHttpRequest();

      if (type) {
        activeRequests.set(type, request);
      }

      const errorHandler = (
        event: ProgressEvent<XMLHttpRequestEventTarget>
      ) => {
        if (type) {
          activeRequests.delete(type);
        }
        reject(event);
      };

      const loadHandler = (event: ProgressEvent<XMLHttpRequestEventTarget>) => {
        const withHeaders = event as ProgressEvent<XMLHttpRequestWithHeaders>;

        if (withHeaders.target !== null) {
          withHeaders.target.headers = headerStringToObject(
            request.getAllResponseHeaders()
          );

          if (withHeaders.target.status < 400) {
            if (type) {
              activeRequests.delete(type);
            }

            resolve(
              withHeaders as SuccessfulProgressEvent<XMLHttpRequestWithHeaders>
            );
            return;
          }
        }

        errorHandler(withHeaders);
      };

      request.addEventListener("load", loadHandler);
      request.addEventListener("error", errorHandler);

      request.open(method.toUpperCase(), url);

      if (blob) {
        request.responseType = "arraybuffer";
      }

      if (headers) {
        Object.keys(headers).forEach((key) => {
          request.setRequestHeader(key, headers[key]);
        });
      }

      if (data instanceof FormData) {
        request.send(data);
      } else if (data) {
        request.send(JSON.stringify(data));
      } else {
        request.send();
      }
    }
  );
}

export async function getXml(url: string, type?: string) {
  return promiseRequest(
    "GET",
    url,
    undefined,
    concatHeaders({ Accept: "application/xml" }, globalHeadersAsObject()),
    type
  );
}

export async function get(url: string, type?: string) {
  return promiseRequest(
    "GET",
    url,
    undefined,
    concatHeaders(undefined, globalHeadersAsObject()),
    type
  );
}

export async function getBlob(url: string, type?: string) {
  return promiseRequest(
    "GET",
    url,
    undefined,
    concatHeaders(undefined, globalHeadersAsObject()),
    type,
    true
  );
}

export async function getResponseJson<T = any>(url: string, type?: string) {
  const response = await get(url, type);
  try {
    return JSON.parse(response.target.responseText) as T;
  } catch (error) {
    return undefined;
  }
}

export async function post(
  url: string,
  data?: any,
  type?: string,
  headers: object = {}
) {
  return promiseRequest(
    "POST",
    url,
    data,
    concatHeaders(headers, globalHeadersAsObject()),
    type
  );
}

export async function postForm(
  url: string,
  data: any,
  type?: string,
  headers: object = {}
) {
  return promiseRequest(
    "POST",
    url,
    data,
    concatHeaders(headers, globalHeadersAsObject()),
    type
  );
}

export async function postAndReturnResponseJson<T = any>(
  url: string,
  data?: any,
  type?: string
) {
  const response = await post(url, data, type, {
    "Content-Type": "application/json",
  });

  return JSON.parse(response.target.responseText) as T;
}

export async function put(url: string, data?: any, type?: string) {
  return promiseRequest(
    "PUT",
    url,
    data,
    concatHeaders(
      { "Content-Type": "application/json" },
      globalHeadersAsObject()
    ),
    type
  );
}

export async function putAndReturnResponseJson<T = any>(
  url: string,
  data?: any,
  type?: string
) {
  const response = await put(url, data, type);
  return JSON.parse(response.target.responseText) as T;
}

export async function restDelete(
  url: string,
  data?: any,
  type?: string,
  apiVersion?: string,
  apiPrefix?: string
) {
  return promiseRequest(
    "DELETE",
    url,
    data,
    concatHeaders(undefined, globalHeadersAsObject()),
    type
  );
}

/**
 * Sets a global header value for all requests
 * @param {string} name The header key
 * @param {string} value The value
 */
export function setGlobalHeader(name: string, value: string) {
  globalHeaders.set(name, value);
}

/**
 * Unsets a global header value
 * @param {string} name The header name to remove
 */
export function unsetGlobalHeader(name: string) {
  globalHeaders.delete(name);
}

/**
 * Removes all global headers
 */
export function removeAllGlobalHeaders() {
  globalHeaders.clear();
}

function headerStringToObject(headerString: string) {
  return headerString
    .split("\r\n")
    .map((hdr) => hdr.split(": "))
    .reduce((object, hdr) => {
      return { ...object, [hdr[0].toLowerCase()]: hdr[1] };
    }, {});
}

function globalHeadersAsObject() {
  const returnValue: Headers = {};

  const token = store.getState().oidc.data?.accessToken;

  if (token) {
    returnValue.Authorization = `Bearer ${token}`;
  }

  globalHeaders.forEach((value, key) => (returnValue[key] = value));

  return returnValue;
}

function concatHeaders(headersList?: object, globalHeadersList?: Headers) {
  const headersOut: Headers = {
    ...globalHeadersList,
    ...headersList,
  };

  return headersOut;
}

export async function getAuthStatus() {
  const apiUrl = process.env.REACT_APP_API_URL;

  return fetch(`${apiUrl}/api/oidc/auth-status`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => {
    return data.status;
  });
}

// export function handleFetchError(
//   dispatch: RootDispatch,
//   error: string,
//   action: RootAction
// ) {
//   if (process.env.NODE_ENV === "development") {
//     // tslint:disable-next-line: no-console
//     console.log(error);
//   }

//   if (
//     ((error as unknown as ProgressEvent)?.target as XMLHttpRequest).status ===
//     401
//   ) {
//     store.dispatch(removeState());
//   }

//   return dispatch(action);
// }
