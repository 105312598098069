import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  infoText: {
    fontFamily: theme.hypoFonts.gotham.book,
    color: "#646363",
    marginTop: 0,
    marginBottom: 0,
    width: "100%",
    fontSize: "14px",
  },
}));

interface Props {
  text: string;
  style?: React.CSSProperties;
}

const InfoText = (props: Props) => {
  const { style, text } = props;
  const { classes } = useStyles();
  return (
    <p className={classes.infoText} style={{ ...style }} tabIndex={0}>
      {text}
    </p>
  );
};

export default InfoText;
