import React, { useEffect } from "react";
import { Layout } from "components/Layout";
import { Route, Routes, useNavigate } from "react-router-dom";
import ChooseCompanyPage from "views/ChooseCompanyPage";
import { getBaseUrl } from "utils/routeUtils";
import { RouteDescription } from "types/models";
import KYCFormPage from "views/KYCFormPage";
import LoginPage from "views/LoginPage";
import Redirect from "views/Redirect";
import { PrivateRoutes } from "components/PrivateRoute";
import ChooseBankPage from "views/ChooseBankPage";
import LogoutPage from "views/LogoutPage";
import { useAppDispatch, useTypedSelector } from "redux/store";
import { getAuthInfoSelector, removeOidcState } from "redux/oidc";
import {
  getCurrentCompanySelector,
  removeCompaniesState,
} from "redux/companies";
import ErrorPage from "views/ErrorPage";

const baseUrl = getBaseUrl();

export enum RoutesEnum {
  Login = "/login",
  Identify = "/identify",
  Redirect = "/redirect",
  Logout = "/logout",
  Error = "/error",
  Root = "/",
}

export enum PrivateRoutesEnum {
  ChooseCompany = "/choosecompany",
  FillForm = "/fillform",
  Others = "*",
}

export const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const authInfo = useTypedSelector(getAuthInfoSelector);
  const currentCompany = useTypedSelector(getCurrentCompanySelector);

  useEffect(() => {
    if (authInfo?.accessToken) {
      if (currentCompany) {
        navigate(PrivateRoutesEnum.FillForm);
      } else {
        navigate(PrivateRoutesEnum.ChooseCompany);
      }
    }
  }, [authInfo?.accessToken, currentCompany, navigate]);

  useEffect(() => {
    if (authInfo?.accessToken) {
      const timer = window.setTimeout(() => {
        dispatch(removeOidcState());
        dispatch(removeCompaniesState());
      }, 5400000); // 5 400 000 milliseconds = 90 minutes

      return () => {
        clearTimeout(timer);
      };
    }
  }, [authInfo?.accessToken, dispatch]);

  const routes: RouteDescription[] = React.useMemo(
    () => [
      {
        to: RoutesEnum.Root,
        title: "",
      },
      {
        to: PrivateRoutesEnum.ChooseCompany,
        title: "Yrityksen valinta",
        navTitle: undefined,
      },
      {
        to: PrivateRoutesEnum.FillForm,
        title: "Asiakkaan tuntemistiedot",
        navTitle: "Asiakkaan tuntemistiedot",
      },
      {
        to: RoutesEnum.Login,
        title: "",
      },
      {
        to: RoutesEnum.Logout,
        title: "",
      },
      {
        to: RoutesEnum.Error,
        title: "",
      },
    ],
    []
  );

  return (
    <Layout basename={baseUrl} routes={routes}>
      {/* <Page /> */}
      <Routes>
        <Route path={RoutesEnum.Login} element={<LoginPage />} />
        <Route path={RoutesEnum.Logout} element={<LogoutPage />} />
        <Route path={RoutesEnum.Redirect} element={<Redirect />} />
        <Route path={RoutesEnum.Identify} element={<ChooseBankPage />} />
        <Route path={RoutesEnum.Root} element={<LoginPage />} />
        <Route path={RoutesEnum.Error} element={<ErrorPage />} />

        <Route element={<PrivateRoutes />}>
          <Route
            path={PrivateRoutesEnum.ChooseCompany}
            element={<ChooseCompanyPage />}
          />
          <Route path={PrivateRoutesEnum.FillForm} element={<KYCFormPage />} />
          <Route path={PrivateRoutesEnum.Others} element={<div></div>} />
        </Route>
      </Routes>
    </Layout>
  );
};

export default App;
