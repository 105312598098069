import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ComboBox, { defaultItemToString } from "../ComboBox";
import BottomRow from "./BottomRow";
import { useAppDispatch, useTypedSelector } from "redux/store";
import { popupErrorMessageSelector, setPopupErrorMessage } from "redux/ui";

const useStyles = makeStyles()((theme) => ({
  background: {
    backgroundColor: "#F5FAFE",
  },
  contentContainer: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  firstContainer: {
    paddingTop: "35px",
    paddingBottom: "20px",
  },
  secondaryContainer: {
    paddingTop: 0,
    paddingBottom: "20px",
  },
  bulletinButton: {
    minWidth: "90px",
    marginLeft: "18px",
  },
  codeUsedErrorText: {
    color: "#646363",
    fontFamily: theme.hypoFonts.gotham.book,
    fontSize: 11,
    marginTop: 5,
  },
}));

interface AddCompanyContentProps {
  onClose?: (
    event: React.MouseEvent<SVGSVGElement | HTMLButtonElement, MouseEvent>
  ) => void;
  onSubmit?: () => void;
  onInputChangeValue?: (value: string, fieldName: string) => void;
  loading?: boolean;
}

export default function AddCompanyContent(
  props: AddCompanyContentProps
): JSX.Element {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { onClose, onSubmit, onInputChangeValue, loading } = props;

  const popupErrorMessage = useTypedSelector(popupErrorMessageSelector);

  return (
    <Grid container className={classes.background}>
      <Grid item xs={12}>
        <Grid container className={classes.contentContainer}>
          <Grid item xs={12} className={classes.firstContainer}>
            <ComboBox
              items={[]}
              itemToString={defaultItemToString}
              label={"Syötä kertakäyttökoodi"}
              placeholder={"Kertakäyttökoodi"}
              showButton={false}
              onInputValueChange={(value: string) => {
                if (onInputChangeValue) {
                  dispatch(
                    setPopupErrorMessage({
                      visible: false,
                    })
                  );
                  onInputChangeValue(value, "oneTimeCode");
                }
              }}
              useAsInputOnly
            />
            {popupErrorMessage.visible && (
              <Typography className={classes.codeUsedErrorText}>
                {popupErrorMessage.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <BottomRow
              leftButtonLabel={"Peruuta"}
              rightButtonLabel={"Lähetä"}
              onLeftButtonClick={onClose}
              onRightButtonClick={onSubmit}
              rightButtonDisabled={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
