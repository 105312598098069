import React from "react";
import { Checkbox } from "@mui/material";
import { WrappedFieldProps } from "redux-form";
import { Common } from "../Form/Common";

interface ExtendedProps {
  customCheckboxIcon?: React.ReactNode;
  customCheckboxIconChecked?: React.ReactNode;
}

const renderCheckBox = (props: Common & WrappedFieldProps & ExtendedProps) => {
  const {
    input,
    meta,
    style,
    label,
    customCheckboxIcon,
    customCheckboxIconChecked,
    ...nonprops
  } = props;

  let t = style || {};

  const styles = {
    ...t,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Checkbox
        {...nonprops}
        {...input}
        checked={!!input.value}
        value={input.value.toString()}
        style={styles}
        icon={customCheckboxIcon}
        checkedIcon={customCheckboxIconChecked}
      />
      <span
        style={{
          color: "red",
          fontSize: 11,
          marginRight: 10,
          marginBottom: -10,
          position: "absolute",
          bottom: 0,
        }}
        tabIndex={0}
      >
        {meta.touched && meta.error}
      </span>
    </div>
  );
};

export default renderCheckBox;
