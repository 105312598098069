import React from "react";
import { RouteDescription } from "types/models";
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import {
  Grid,
  Divider,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import HYPOLogo from "./icons/HYPOLogo";
import Avatar from "./icons/Avatar";
import { useAppDispatch, useTypedSelector } from "redux/store";
import {
  getCurrentCompanySelector,
  removeCompaniesState,
} from "redux/companies";
import { formatBusinessNumber } from "utils/stringUtils";
import { PrivateRoutesEnum, RoutesEnum } from "App";
import { getAuthInfoSelector, removeOidcState } from "redux/oidc";

const useStyles = makeStyles()((theme) => ({
  appBar: {
    background:
      "linear-gradient(45deg, #004170 -4.5%, #004170 58.72%, #0069B4 93.3%)",
    height: "46px",
  },
  borderBottom: {
    height: "4px",
    background:
      "linear-gradient(90deg, rgba(236, 102, 8, 0) 23.3%, #EC6608 51.05%, #EC6608 78.51%, rgba(236, 102, 8, 0) 95.16%)",
  },
  topMargin: {
    marginTop: "4px",
  },
  container: {
    height: "100%",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  logoWrapper: {
    [theme.breakpoints.not("xl")]: {
      paddingLeft: 10,
    },
  },
  divider: {
    backgroundColor: "#FFFFFF",
    height: "70%",
    alignSelf: "center",
  },
  pageHeaderContainer: {
    display: "flex",
    paddingLeft: "30px",
    alignSelf: "center",
    justifyContent: "flex-start",
  },
  barText: {
    fontFamily: theme.hypoFonts.gotham.book,
    fontSize: "clamp(0.8em, 0.714vw, 0.714vw)",
    color: "#FFFFFF",
  },
  barTextRight: {
    marginLeft: "0.7vw",
  },
  userContainer: {
    display: "flex",
    paddingRight: "30px",
    alignSelf: "center",
    justifyContent: "flex-end",
  },
  usernameContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
  },
  logoutContainer: {
    marginLeft: "0.7vw",
    maxWidth: "fit-content !important",
    alignItems: "center",
  },
  logoutText: {
    marginLeft: "0.7vw",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

type TopBarProps = {
  basename: string;
  routes?: RouteDescription[];
};

export const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {
  const { classes } = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.not("xl"));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentCompany = useTypedSelector(getCurrentCompanySelector);
  const authInfo = useTypedSelector(getAuthInfoSelector);

  const routes =
    props.routes?.flatMap((current) => {
      if (current.subRoutes) {
        return [current, current.subRoutes].flat();
      }
      return current;
    }) ?? [];
  const activeRoute = routes.findIndex((it) => it.to === location.pathname);

  const logOut = () => {
    dispatch(removeOidcState());
    dispatch(removeCompaniesState());
    navigate(RoutesEnum.Logout);
  };

  return (
    <AppBar className={classes.appBar} position="fixed">
      <Grid container className={`${classes.container} ${classes.topMargin}`}>
        <Grid item xs={3} className={classes.logoContainer}>
          <Grid item xl={5} sm={0}></Grid>
          <Grid item xl={7} sm={12} className={classes.logoWrapper}>
            <Link href="/">
              <HYPOLogo />
            </Link>
          </Grid>
        </Grid>
        <Divider orientation={"vertical"} className={classes.divider} />
        <Grid item xs>
          <Grid container className={classes.container}>
            <Grid item xs={6} className={classes.pageHeaderContainer}>
              <Typography className={classes.barText}>
                {routes[activeRoute]?.title ?? null}
                {currentCompany &&
                  location.pathname === PrivateRoutesEnum.FillForm &&
                  ` - ${currentCompany?.name} (${formatBusinessNumber(
                    currentCompany?.businessNumber ?? ""
                  )})`}
              </Typography>
            </Grid>
            {authInfo?.accessToken && (
              <Grid item xs={6} className={classes.userContainer}>
                <Grid
                  container
                  item
                  xs={8}
                  className={classes.usernameContainer}
                >
                  <Avatar small={matches} />
                  <Typography
                    className={`${classes.barText} ${classes.barTextRight}`}
                    data-testid="topbar-username"
                  >
                    {authInfo?.name ?? ""}
                  </Typography>
                </Grid>
                <Grid container item xs={4} className={classes.logoutContainer}>
                  <Divider
                    orientation={"vertical"}
                    className={classes.divider}
                  />
                  <Link onClick={logOut}>
                    <Typography
                      className={`${classes.barText} ${classes.logoutText}`}
                    >
                      Kirjaudu ulos
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.borderBottom}></div>
    </AppBar>
  );
};

export default TopBar;
