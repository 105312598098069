import {
  PayloadAction,
  SerializedError,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
import { KYCForm, KYCSaveStatus } from "types/models";
import { KYCDataDto } from "models/KYCDtos";
import { getKYCForm, saveKYCData } from "./kyc/kyc-actions";
import { KYCSaveStatusEnum, KYCSaveStatusMessage } from "types/enums";

export interface KYCState {
  loading: boolean;
  error?: SerializedError | null;
  form?: KYCForm;
  initialData?: KYCDataDto;
  saveStatus?: KYCSaveStatus;
}

const initialState: KYCState = {
  loading: true,
};

export const kycSlice = createSlice({
  name: "kyc",
  initialState: initialState,
  reducers: {
    setKycSaveStatus: (state, action: PayloadAction<KYCSaveStatus>) => {
      state.saveStatus = action.payload;
    },
    resetKycSaveStatus: (state) => {
      state.saveStatus = {
        visible: false,
        status: KYCSaveStatusEnum.None,
        message: KYCSaveStatusMessage.None,
      };
    },
    setInitialKycData: (state, action: PayloadAction<KYCDataDto>) => {
      state.initialData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKYCForm.pending, (state, _action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getKYCForm.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(
      getKYCForm.fulfilled,
      (state, action: PayloadAction<KYCForm | undefined>) => {
        state.loading = false;
        if (action.payload !== undefined) {
          state.form = action.payload;
        }
      }
    );
    builder.addCase(saveKYCData.pending, (state, _action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(saveKYCData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(saveKYCData.fulfilled, (state, _action) => {
      state.loading = false;
      state.error = null;
    });
  },
});

export const kycFormSelector = createSelector(
  (state: RootState): KYCForm | undefined => state.kyc.form,
  (it) => it
);

export const kycSaveStatusSelector = createSelector(
  (state: RootState): KYCSaveStatus | undefined => state.kyc.saveStatus,
  (it) => it
);

export const initialKycDataSelector = createSelector(
  (state: RootState): KYCDataDto | undefined => state.kyc.initialData,
  (it) => it
);

export const { setKycSaveStatus, resetKycSaveStatus, setInitialKycData } =
  kycSlice.actions;
