import * as request from "utils/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createCustomAction } from "typesafe-actions";
import { AuthInfo } from "types/models";

const apiUrl = process.env.REACT_APP_API_URL;

export const emptyActionType = "oidc/empty";

export const empty = createCustomAction(emptyActionType);

export const postToken = createAsyncThunk(
  "oidc/postToken",
  async (data: { code: string; state: string }) => {
    try {
      const res = await request.postAndReturnResponseJson<AuthInfo>(
        `${apiUrl}/api/oidc/postToken?code=${data.code}&state=${data.state}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  }
);

export const postTokenDev = createAsyncThunk(
  "oidc/postTokenDev",
  async (data: { code: string; state: string }) => {
    try {
      const res = await request.postAndReturnResponseJson(
        `${apiUrl}/api/oidc/token?code=${data.code}&state=${data.state}&error=""`
      );

      return res;
    } catch (error) {
      throw error;
    }
  }
);

export const logout = createAsyncThunk("oidc/logout", async () => {
  try {
    const res = await request.postAndReturnResponseJson(
      `${apiUrl}/api/oidc/logout`
    );
    return res;
  } catch (error) {
    throw error;
  }
});

export const removeOidcState = empty;
