import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { LoginButton } from "components/LoginButton";
import { loadAuthenticationInfos } from "redux/authenticationInfos/authenticationInfo-actions";
import { useAppDispatch } from "redux/store";

const useStyles = makeStyles()((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "65%",
  },
  textContainer: {
    marginBottom: "3em",
    marginTop: "4em",
  },
  header: {
    textAlign: "center",
    fontFamily: theme.hypoFonts.gotham.bold,
    color: theme.hypoColors.darkGrey,
    fontSize: "1.5em",
    marginBottom: "1.5em",
  },
  infoText: {
    textAlign: "center",
    fontFamily: theme.hypoFonts.gotham.book,
    color: theme.hypoColors.darkGrey,
    margin: "1em 0",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "65%",
    width: "65%",
  },
}));

export const LoginPage = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(loadAuthenticationInfos());
  }, [dispatch]);

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid container item xs={8} className={classes.container}>
        <Grid item xs={12} className={classes.logoContainer}>
          <img
            className="nav-logo"
            src="https://www.hypo.fi/wp-content/uploads/2015/06/hypo_sin_rgb_web.jpg"
            alt="Hypo Logo"
            width={"65%"}
          />
        </Grid>
        <Grid item xs={12} className={classes.textContainer}>
          <Typography className={classes.header}>
            Asiakkaan tuntemistietojen päivittäminen
          </Typography>
          <Typography className={classes.infoText}>
            Voit päivittää asiakkaan tuntemistietosi Suomen
            Hypoteekkiyhdistykselle saamasi kertakäyttötunnuksen ja
            pankkitunnistautumisen avulla.
          </Typography>
          <Typography className={classes.infoText}>
            Aloita tietojen päivitys tunnistautumalla.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <LoginButton
            text="Kirjaudu pankkitunnuksilla"
            disabled={false}
            showSpinner={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(LoginPage);
