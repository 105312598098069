import { createAsyncThunk } from "@reduxjs/toolkit";
import * as request from "utils/request";
import { Country } from "types/interfaces";

const apiUrl = process.env.REACT_APP_API_URL;

export const getAllCountries = createAsyncThunk(
  "kyc/get-all-countries",
  async () => {
    try {
      const response = await request.getResponseJson<Country[]>(
        `${apiUrl}/api/country/all`
      );

      return response;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);
