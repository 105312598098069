import { combineReducers } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";
import { companiesSlice } from "./companies";
import { uiSlice } from "./ui";
import { kycSlice } from "./kyc";
import { countriesSlice } from "./countries";
import { authenticationInfoSlice } from "./authenticationInfo";
import { oidcSlice } from "./oidc";
import { userSlice } from "./user";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistOidcConfig = {
  key: "oidc",
  storage,
  blacklist: ["isLoggedOut"],
};

const persistUserConfig = {
  key: "user",
  storage,
};

const persistedOidcReducer = persistReducer(
  persistOidcConfig,
  oidcSlice.reducer
);
const persistedUserReducer = persistReducer(
  persistUserConfig,
  userSlice.reducer
);

const rootReducer = combineReducers({
  authenticationInfo: authenticationInfoSlice.reducer,
  oidc: persistedOidcReducer,
  form: formReducer,
  companies: companiesSlice.reducer,
  countries: countriesSlice.reducer,
  kyc: kycSlice.reducer,
  ui: uiSlice.reducer,
  user: persistedUserReducer,
});

export default rootReducer;
