import { Answer, Question } from "types/models";
import { Country } from "types/interfaces";
import { DynamicFormAPI, KYCQuestionType } from "types/enums";
import { KYCDataDto } from "../models/KYCDtos";

export const isRequired = (question?: Question, formState?: any): boolean => {
  if (formState && question) {
    //NOTE(tonim): Disabled until taxationNumberNotGranted is implemented in cLA
    // if (
    //     question.name.startsWith("countriesWithTaxation") &&
    //     question.name.endsWith("taxationNumber")
    // ) {
    //     const index = Number(question.name.split("[")[1].split("]")[0]);
    //     return (
    //         formState.countriesWithTaxation[index] &&
    //         !formState.countriesWithTaxation[index].taxationNumber
    //     );
    // }
    if (
      question.name.startsWith("countriesWithTaxation") &&
      question.name.endsWith("taxationNumber")
    ) {
      const index = Number(question.name.split("[")[1].split("]")[0]);

      return formState.countriesWithTaxation[index]?.taxationCountry === "FI"
        ? false
        : formState.countriesWithTaxation[index]?.taxationCountry !== undefined;
    }

    if (question.name === "nonHypoServices") {
      return formState.hypoServices === undefined;
    }

    if (question.name === "hypoServices") {
      return formState.nonHypoServices === undefined;
    }

    if (question.name === "sourceOfFunds") {
      return formState.hypoServices !== undefined;
    }

    return question.mandatory;
  }

  return true;
};

export const mapCountryAnswers = (
  params:
    | { filter: string; answers: Answer[]; countries: undefined }
    | { filter: string; countries: Country[]; answers: undefined }
): {
  label: string;
  value: string;
}[] => {
  if (params.answers) {
    return [
      ...params.answers
        .filter((a) => a.titleFI === params.filter)
        .map((a) => {
          return {
            label: a.titleFI,
            value: a.externalId,
          };
        }),
      ...params.answers
        .filter((a) => a.titleFI !== params.filter)
        .map((a) => {
          return {
            label: a.titleFI,
            value: a.externalId,
          };
        }),
    ];
  } else {
    return [
      ...params
        .countries!.filter((a) => a.countryNameFI === params.filter)
        .map((a) => {
          return {
            label: a.countryNameFI,
            value: a.countryCode,
          };
        }),
      ...params
        .countries!.filter((a) => a.countryNameFI !== params.filter)
        .map((a) => {
          return {
            label: a.countryNameFI,
            value: a.countryCode,
          };
        }),
    ];
  }
};

export const mapCheckboxAnswers = (
  question: {
    id: string;
    name: string;
    answers?: Answer[];
    hasPredefinedAnswers: boolean;
    api?: DynamicFormAPI;
    questionType?: KYCQuestionType;
  },
  data: KYCDataDto
) => {
  const questionNameKey = question.name as keyof KYCDataDto;
  if (
    question.questionType !== KYCQuestionType.CheckBox ||
    question.api === DynamicFormAPI.IP
  ) {
    return data[questionNameKey];
  } else {
    if (question.answers) {
      const searchCriteria = data[questionNameKey] ? "Kyllä" : "Ei";

      const answer = question.answers.find((a) => a.titleFI === searchCriteria);
      return answer && answer.externalId;
    }
  }
};
