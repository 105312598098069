import { SerializedError, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { emptyActionType, postToken } from "./oidc/oidc-actions";
import { AuthInfo } from "types/models";

export interface OidcState {
  loading?: boolean;
  error: SerializedError | null;
  data?: AuthInfo | null;
}

const initialState: OidcState = {
  loading: false,
  error: null,
  data: null,
};

export const oidcSlice = createSlice({
  name: "oidc",
  initialState: initialState,
  reducers: {
    removeOidcState: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postToken.pending, (state, _action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postToken.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(postToken.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(emptyActionType, (state) => {
      state = initialState;
    });
  },
});

export const getAuthInfoSelector = createSelector(
  (state: RootState): AuthInfo | null | undefined => state.oidc.data,
  (it) => it
);

export const oidcErrorSelector = createSelector(
  (state: RootState): SerializedError | null => state.oidc.error,
  (it) => it
);

export const { removeOidcState } = oidcSlice.actions;
