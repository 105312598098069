import React from "react";
import { Grid, Button, Typography, CircularProgress } from "@mui/material";
import {
  reduxForm,
  InjectedFormProps,
  arrayPush,
  arrayRemove,
  getFormValues,
} from "redux-form";
import { makeStyles } from "tss-react/mui";
import { AppDispatch, RootState, useTypedSelector } from "../../redux/store";
import { connect } from "react-redux";
import { KYCDataDto } from "models/KYCDtos";
import { Group, KYCForm } from "types/models";
import { KYCSaveStatusEnum } from "types/enums";
import { Country } from "types/interfaces";
import CustomLensIcon from "components/icons/CustomLensIcon";
import StatusText from "./StatusText";
import { kycSaveStatusSelector } from "redux/kyc";
import validateKYCForm from "utils/KYCFormValidator";
import RenderQuestionByType from "./RenderQuestionByType";

const useStyles = makeStyles()((theme) => ({
  textArea: {
    marginBottom: 20,
    fontSize: 14,
    color: theme.hypoColors.darkGrey,
    fontFamily: theme.hypoFonts.gotham.book,
  },
  groupHeader: {
    color: theme.hypoColors.darkGrey,
    fontFamily: theme.hypoFonts.gotham.bold,
    fontSize: "18px",
    marginBottom: 0,
    padding: "8px 0",
  },
  loadingContainer: {
    padding: "20px 0",
  },
  bold: {
    fontFamily: theme.hypoFonts.gotham.bold,
    margin: 0,
  },
  questionContainer: {
    paddingLeft: 8,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
}));

interface OwnProps {
  form: string;
  onSubmit: (data: any) => Promise<void>;
  initialValues?: KYCDataDto;
}

interface KYCFormStateProps {
  loading: boolean;
  formState: any;
  kycForm?: KYCForm;
  countries?: Country[];
}
interface KYCFormDispatchProps {
  addItemToFieldArray: (
    formName: string,
    formField: string,
    value: any
  ) => void;
  removeItemFromFieldArray: (
    formName: string,
    formField: string,
    index: number
  ) => void;
}

export type KYCFormProps = OwnProps &
  Partial<KYCFormStateProps & KYCFormDispatchProps> &
  InjectedFormProps<KYCDataDto, OwnProps>;

const DynamicKYCForm: React.FC<KYCFormProps> = (props: KYCFormProps) => {
  const { classes } = useStyles();
  const { invalid } = props;

  const kycSaveStatus = useTypedSelector(kycSaveStatusSelector);

  const renderQuestions = (group: Group) => {
    return group.questions.map((question, index) => {
      return (
        <Grid
          key={`${question.name}${question.id}`}
          className={classes.questionContainer}
        >
          <RenderQuestionByType
            question={question}
            kycFormProps={props}
            key={`${question.name}_${index}`}
          />
        </Grid>
      );
    });
  };

  const renderGroups = () => {
    const groupCount = props.kycForm && props.kycForm.groups?.length;

    return (
      props.kycForm &&
      props.kycForm.groups?.map((group, index) => {
        return (
          <Grid key={`${group.titleFI}${group.id}`} container>
            <Grid item xs={12}>
              <Grid container>
                <h4 className={classes.groupHeader} tabIndex={0}>
                  {`${index + 1}/${groupCount} ${group.titleFI}`}
                </h4>
              </Grid>
              {renderQuestions(group)}
            </Grid>
          </Grid>
        );
      })
    );
  };

  return (
    <Grid>
      {props.loading ? (
        <Grid
          container
          justifyContent="center"
          className={classes.loadingContainer}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {kycSaveStatus?.visible && (
            <StatusText
              statusText={kycSaveStatus.message}
              isError={kycSaveStatus.status === KYCSaveStatusEnum.Failed}
            />
          )}
          <form onSubmit={props.handleSubmit}>
            <Typography
              id="kycFormStartText"
              className={classes.textArea}
              tabIndex={0}
            >
              Suomen Hypoteekkiyhdistyksellä (Hypo) ja Suomen Asuntohypopankki
              Oy:llä (AHP) on lain nojalla velvollisuus hankkia asiakkaistaan
              asiakassuhteen perustamisen yhteydessä laissa säädetyt
              tuntemistiedot. Tuntemistietoja on säännöllisesti päivitettävä
              myös asiakassuhteen aikana. Tälle sivulle on koottu asiointinne
              yhteydessä saadut tuntemistiedot, jotka Hypo ja AHP ovat
              velvollisia säilyttämään vähintään laissa säädetyn määräajan.
            </Typography>
            <Typography className={classes.textArea} tabIndex={0}>
              Pakolliset kysymykset on merkitty
              {<CustomLensIcon />} -merkillä
            </Typography>
            <Typography className={classes.textArea} tabIndex={0}>
              <p className={classes.bold}>HUOM.</p> Jos haluat kopion
              kysymyksistä/vastauksistasi, ole hyvä ja käytä selaimen tulostus
              toimintoa
            </Typography>
            {renderGroups()}
            <Grid container>
              <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
                <h5 style={{ fontWeight: "normal" }} tabIndex={0}>
                  HENKILÖTIETOJEN KÄSITTELY
                </h5>
                <Typography className={classes.textArea} tabIndex={0}>
                  Tämä sivu sisältää Suomen Hypoteekkiyhdistyksen ja Suomen
                  Asuntohypopankki Oy:n asiakkaiden henkilötietoja. Suomen
                  Hypoteekkiyhdistys ja Suomen Asuntohypopankki Oy käsittelevät
                  rekisterinpitäjinä henkilötietoja Hypo-konsernin
                  henkilörekistereistä julkistettujen tietosuojaselosteiden
                  mukaisella tavalla. Tietosuojaselosteet ovat saatavissa Hypon
                  verkkosivuilla osoitteessa{" "}
                  <a href="https://www.hypo.fi/tietosuoja">
                    www.hypo.fi/tietosuoja
                  </a>
                  .
                </Typography>
                <h5 style={{ fontWeight: "normal" }} tabIndex={0}>
                  TUNTEMISTIETOJEN KÄYTTÄMINEN
                </h5>
                <Typography className={classes.textArea} tabIndex={0}>
                  Asiakkaan tuntemistietoja ja muita henkilötietoja voidaan
                  käyttää rahanpesun ja terrorismin rahoittamisen estämiseen,
                  paljastamiseen ja selvittämiseen sekä rahanpesun ja
                  terrorismin rahoittamisen ja sen rikoksen, jolla rahanpesun
                  tai terrorismin rahoittamisen kohteena oleva omaisuus tai
                  rikoshyöty on saatu, tutkintaan saattamista varten.
                </Typography>
                <Button
                  fullWidth={true}
                  variant="text"
                  type="submit"
                  style={{ minHeight: "54px", minWidth: 0 }}
                >
                  Tallenna
                </Button>
                {invalid && (
                  <Typography style={{ color: "red" }} variant="body2">
                    Lomakkeella täyttämättömiä pakollisia kenttiä
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Grid>
  );
};

const kycForm = reduxForm<KYCDataDto, OwnProps>({
  form: "kycForm",
  enableReinitialize: true,
  validate: validateKYCForm,
  shouldValidate: () => true,
})(DynamicKYCForm);

const mapStateToProps = (state: RootState): KYCFormStateProps => {
  return {
    loading: state.kyc.loading,
    formState: getFormValues("kycform")(state),
    kycForm: state.kyc.form,
    countries: state.countries.all,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): KYCFormDispatchProps => {
  return {
    addItemToFieldArray: (formName: string, formField: string, value: any) =>
      dispatch(arrayPush(formName, formField, value)),
    removeItemFromFieldArray: (
      formName: string,
      formField: string,
      index: number
    ) => dispatch(arrayRemove(formName, formField, index)),
  };
};

export default connect<
  KYCFormStateProps,
  KYCFormDispatchProps,
  OwnProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps
)(kycForm as any);
