import { KYCDataDto, TaxationData } from "models/KYCDtos";
import { FormErrors } from "redux-form";
import {
  required,
  validEmail,
  validNumberInput,
  validTaxationNumber,
  validateFreeTextInput,
} from "utils/validators";

const validateKYCForm = (values: KYCDataDto) => {
  const errors: any = {};

  validateBasicInfo(values, errors);
  validateBusiness(values, errors);
  validateCustomership(values, errors);
  validatePaymentInformation(values, errors);
  validateTaxInfo(values, errors);
  validateConfirmation(values, errors);

  return errors;
};

// const validateSelectionList = (values: string[]) => {
//   const selectionListerrors: any[] = [];
//   values.forEach((v, idx) => {
//     if (!v) {
//       selectionListerrors[idx] = required(v);
//     }
//   });
//   return selectionListerrors;
// };

const validateBasicInfo = (values: KYCDataDto, errors: any) => {
  errors.LeiCode =
    values.LeiCode &&
    validateFreeTextInput(values.LeiCode, "LEI-tunnuksen", 20, false);
  errors.mainAddress = validateFreeTextInput(
    values.mainAddress,
    "Osoitteen",
    50,
    true
  );
  errors.zipCode = validateFreeTextInput(values.zipCode, "Osoitteen", 5, true);
  errors.city = validateFreeTextInput(
    values.city,
    "Postitoimipaikan",
    30,
    true
  );
  errors.registeredOfficeCity =
    values.registeredOfficeCity &&
    validateFreeTextInput(values.registeredOfficeCity, "Kotipaikan", 20, false);
  errors.homeCountry = required(values.homeCountry);
  errors.phoneNumber = validateFreeTextInput(
    values.phoneNumber,
    "Puhelinnumeron",
    20,
    true
  );
  errors.email =
    required(values.email) || (values.email && validEmail(values.email));
};

const validateBusiness = (values: KYCDataDto, errors: any) => {
  errors.numberOfEmployees = validNumberInput(values.numberOfEmployees);
  errors.turnover = validNumberInput(values.turnover);
  errors.balance = validNumberInput(values.balance);
};

const validateCustomership = (values: KYCDataDto, errors: any) => {
  if (!values.nonHypoServices) {
    errors.hypoServices = required(values.hypoServices);
  }
  if (!values.hypoServices) {
    errors.nonHypoServices = required(values.nonHypoServices);
  }
  if (values.hypoServices) {
    errors.sourceOfFunds = required(values.sourceOfFunds);
  } else {
    errors.sourceOfFunds = undefined;
  }
};

const validatePaymentInformation = (values: KYCDataDto, errors: any) => {
  //errors.incomingTransactionsToHypoEur = required(
  //  values.incomingTransactionsToHypoEur
  //);
  //errors.incomingTransactionsToHypoNumber = required(
  //  values.incomingTransactionsToHypoNumber
  //);
  //errors.outgoingTransactionsFromHypoEur = required(
  //  values.outgoingTransactionsFromHypoEur
  //);
  //errors.outgoingTransactionsFromHypoNumber = required(
  //  values.outgoingTransactionsFromHypoNumber
  //);
  //NOTE: Tehty hätäasennuksessa vapaaehtoisiksi kentiksi
  //errors.transactionsAbroadNumber = required(values.transactionsAbroadNumber);
  //errors.transactionsAbroadEur = required(values.transactionsAbroadEur);
};

const validateTaxInfo = (values: KYCDataDto, errors: any) => {
  errors.taxationStatus = required(values.taxationStatus);
  errors.giinId =
    values.giinId &&
    validateFreeTextInput(
      values.giinId,
      "Finanssilaitoksen GIIN-tunnuksen",
      19,
      false
    );

  errors.countriesWithTaxation =
    values.countriesWithTaxation &&
    validateCountriesWithTaxation(values.countriesWithTaxation);
};

const validateCountriesWithTaxation = (values: TaxationData[]) => {
  const countriesWithTaxationErrors: FormErrors<TaxationData>[] = [];
  values.forEach((v) => {
    const countriesWithTaxationErrorsSingle: FormErrors<TaxationData> = {};

    if (v?.taxationCountry && v.taxationCountry !== "FI") {
      countriesWithTaxationErrorsSingle.taxationNumber = validTaxationNumber(
        v.taxationNumber
      );
      //NOTE(tonim): Disabled until taxationNumberNotGranted is implemented in cLA
      // if (!v.taxationNumberNotGranted) {
      //     countriesWithTaxationErrorsSingle.taxationNumber =
      //         required(v.taxationNumber) ||
      //         validInputLength(v.taxationNumber, 20, "Verotunnisteen") ||
      //         validTaxationNumber(v.taxationNumber);
      // }
      // if (!v.taxationNumber) {
      //     countriesWithTaxationErrorsSingle.taxationNumberNotGranted = required(
      //         v.taxationNumberNotGranted
      //     );
      // }
    }
    countriesWithTaxationErrors.push(countriesWithTaxationErrorsSingle);
  });

  return countriesWithTaxationErrors;
};

const validateConfirmation = (values: KYCDataDto, errors: any) => {
  errors.confirmation = required(values.confirmation);
};

export default validateKYCForm;
